import { Injector, NgModule } from '@angular/core';
import { APP_BASE_HREF, registerLocaleData, } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { AppInjector } from './core/services/appInjector';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeIt, 'it_IT', localeItExtra);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule
  ],
  exports: [
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/medhub' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.setInjector(injector);// save a injector ref
  }
}
