import { StringUtils } from "src/app/shared/utils/string-utils";

export class JsonConverter {

  public static deserialize<T>(obj: T): T {
    const regex = new RegExp(/"[a-zA-Z0-9_]+":/g);
    const str = JSON.stringify(obj);
    const retval = str.replace(regex, (match) => {
      return JsonConverter.toLowerCamelCaseFromSnakeCase(match);
    });
    return JSON.parse(retval);
  }

  public static serialize<T>(obj: T): T {
    const regex = new RegExp(/"[a-zA-Z0-9_]+":/g);
    const str = JSON.stringify(obj);
    const retval = str.replace(regex, (match) => {
      return JsonConverter.toSnakeCaseFromLowerCamelCase(match);
    });
    return JSON.parse(retval);
  }

  private static toSnakeCaseFromLowerCamelCase(str: string): string {
    const regex = new RegExp(/[a-z0-9][A-Z]/g);
    const renamed = str.replace(regex, (match) => {
      let newStr = match[0] + '_' + match[1].toLowerCase();
      return newStr;
    })
    return renamed;
  }

  private static toLowerCamelCaseFromSnakeCase(str: string): string {
    const regex = new RegExp(/[a-zA-Z0-9]_[a-zA-Z0-9]/g);
    const renamed = str.replace(regex, (match) => {
      let newStr = match[0];
      newStr += match[2].toUpperCase();
      return newStr;
    })
    return renamed;
  }

  private testNameChange(): void {
    let properties: string[] = [];
    for (let i = 0; i < 5000; i++) {
      const randomLength = Math.floor(Math.random() * 20) + 2;
      properties.push(StringUtils.randomAlphaNumberLowercaseWithUnderscore(randomLength));
    }
    const startDate = new Date();
    for (let property of properties) {
      const converted = JsonConverter.toLowerCamelCaseFromSnakeCase(property);
      /*       Logger.log('Stringa originale, lung: ' + property.length, property);
            Logger.log('Stringa convertita', converted); */
    }
    const endDate = new Date();
    const totalTime = (endDate.getTime() - startDate.getTime()) / 1000;
    console.log('Tempo totale conversione: ' + totalTime);
  }

  private testSerialization<T>(obj: T): void {
    const dato = obj;
    console.log('Dato iniziale');
    console.log(dato);
    let serialized = JsonConverter.serialize(dato);
    console.log('Dato rinominato');
    console.log(serialized);
  }

  /*   private testDeserialization(): void {
      const obj: ApiResponse<Addetto> = {
        "error": 0,
        "returnCode": 0,
        "returnCodeMessage": "",
        "returnInfoMessage": "",
        "sqlErrorCode": 0,
        "data": [
          {
            "lingua": "IT",
            "cognome": "Cognome 1",
            "idrow": 93,
            "societa": "1    ",
            "nome": "medico1",
            "ruolo": "MED  ",
            "codice_fiscale": "11255789_49     ",
            "utente_modifica": 36,
            "nascita_data": null,
            "telefono1": "12345",
            "sesso": "M",
            "telefono2": "222245",
            "id_addetto": 93,
            "modifica_data": null,
            "tenant": "proxmed        ",
            "email": "test@gmail.com",
            "partita_iva": "123"
          }
        ],
        "records": [],
        "otherRecords": []
      };
  
      let data: ApiResponse<Addetto>> [] =[];
      for (let i = 0; i < 10000; i++) {
        data.push(Cloner.deepCopy(obj));
      }
  
      console.log('Numero oggetti: ' + data.length);
      const startDate = new Date();
      for (let dato of data) {
        let renamed = JsonConverter.deserialize(dato);
      }
      const endDate = new Date();
      console.log('Tempo totale: ' + (endDate.getTime() - startDate.getTime()) / 1000);
  
      const objStr = JSON.stringify(dato[0]);
      Logger.log('Oggetto originale stringa', objStr);
      console.log('Oggetto rinominato: ' + JsonConverter.deserialize(dato[0]));
    } */
}
