<body>
  <div class="restricted-list-selection-container">
    <back-button [disabled]="disabled || !canGoBack" (click)="moveBack()"></back-button>

    <!-- Select date -->
    <p-dropdown [options]="selectionList" [ngModel]="selectedElement" (ngModelChange)="dropdownSelectionChange()"
      [disabled]="disabled">
      <!-- Formato data selezionata -->
      <ng-template let-item pTemplate="selectedItem">
        {{item | date:'dd/MM/yyyy HH:mm:ss'}}
      </ng-template>
      <!-- Formato elementi della lista -->
      <ng-template let-item pTemplate="item">
        {{item | date:'dd/MM/yyyy HH:mm:ss'}}
      </ng-template>
    </p-dropdown>

    <forward-button [disabled]="disabled || !canGoForward" (click)="moveForward()"></forward-button>
  </div>
</body>