import { ModelBase } from "./model-base";

export abstract class EntityBase<T extends EntityBase<T>> extends ModelBase<T>{
  public readonly idrow: number = 0;
  public readonly id: number = 0;

  public constructor(init?: Partial<T>) {
    super(init);
    this.id = init?.id ?? 0;
    this.idrow = init?.idrow ?? 0;
  }

  public equals(other: T): boolean {
    if (!other) return false;

    return this.id === other.id;
  }
}
