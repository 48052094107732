import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { lastValueFrom } from "rxjs";
import { ApiResponse } from "src/app/shared/models/api-response";
import { API_BASE_URL } from "../../utils/app.constants";
import { AppInjector } from "../appInjector";
import { JsonConverter } from "./helper/json-converter";

/**
 * Servizio rest di base
 */
export abstract class RestBaseService {
  protected readonly SERVICE_BASE_URL: string;
  protected readonly http!: HttpClient;
  private storage = new Map<string, any>();
  protected readonly contactITMsg: string = '\nContattare il supporto IT';

  /**
   * Costruttore per servizio
   * @param baseOffset stringa da aggiungere all'url di base
   */
  constructor(private baseOffset: string) {
    this.SERVICE_BASE_URL = `${API_BASE_URL}${this.baseOffset}/`;
    this.http = AppInjector.getInjector().get(HttpClient);
  }

  /**
 * Estrae il messaggio di errore da una risposta http
 * @param errorReponse risposta con errore
 * @param defaultMessage messaggio di errore di default in caso di problemi
 * @returns Messaggio di errore estratto oppure il default
 */
  protected extractErrorMessage(errorReponse: HttpErrorResponse, defaultMessage: string = ''): string {
    try {
      const apiResponse = ApiResponse.create(errorReponse.error);
      const retval = apiResponse.getErrorMessage();
      if (!retval) throw Error();
      return retval;
    }
    catch (e) {
      switch (errorReponse.status) {
        case 0: defaultMessage += '\nServer non raggiungibile'; break;
        case HttpStatusCode.Unauthorized: defaultMessage += '\nAutorizzazione scaduta o non valida.\nEseguire il logout e procedere nuovamente al login'; break;
        default: defaultMessage = 'Errore sconosciuto';
      }
      return defaultMessage;
    }
  }

  /**
   * Metodo di utility per la gestione delle chiamate PUT
   * @param url indirizzo da chiamare
   * @param body body da inviare
   * @returns @see ApiResponse
   */
  protected async put<T>(url: string, body: any): Promise<ApiResponse<T>> {
    const renamedBody = JsonConverter.serialize(body);
    try {
      const response = await lastValueFrom(this.http.put(url, renamedBody))
        .catch((error: HttpErrorResponse) => {
          throw Error(this.extractErrorMessage(error))
        });

      if (!response) throw Error('Impossibile contattare server, risposta null');
      return ApiResponse.create<T>(JsonConverter.deserialize(response));
    }
    catch (e) {
      return ApiResponse.createErrorWithMessage<T>(String(e));
    }
  }

  /**
 * Metodo di utility per la gestione delle chiamate POST
 * @param url indirizzo da chiamare
 * @param body dati da inviare
 * @returns @see ApiResponse
 */
  protected async post<T>(url: string, body: any): Promise<ApiResponse<T>> {
    const renamedBody = JsonConverter.serialize(body);
    try {
      const response = await lastValueFrom(this.http.post(url, renamedBody))
        .catch((error: HttpErrorResponse) => {
          throw Error(this.extractErrorMessage(error))
        });

      if (!response) throw Error('Impossibile contattare server, risposta null');
      return ApiResponse.create<T>(JsonConverter.deserialize(response));
    }
    catch (e) {
      return ApiResponse.createErrorWithMessage<T>(String(e));
    }
  }

  /**
   * Metodo di utility per la gestione delle chiamate GET
   * @param url indirizzo da chiamare con tutti i queryparams
   * @param cache utilizzare la cache? (per identificare le risorse viene usato l'url sopra)
   * @returns @see ApiResponse
   */
  protected async get<T>(url: string, cache: boolean = false): Promise<ApiResponse<T>> {
    try {
      let response;
      if (cache)
        response = this.storage.get(url);
      if (!response) {
        response = await lastValueFrom(this.http.get(url, { observe: "body", responseType: "json" }))
          .catch((error: HttpErrorResponse) => {
            throw Error(this.extractErrorMessage(error))
          });
      }
      if (!response) throw Error('Impossibile contattare server, risposta null');

      if (cache)
        this.storage.set(url, response);

      const obj = JsonConverter.deserialize(response);
      const retval = ApiResponse.create<T>(obj);
      return retval;
    }
    catch (error) {
      const retval = ApiResponse.createErrorWithMessage<T>(String(error));
      return retval;
    }
  }
}
