import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { MisurazioneConsultoStatoTipi } from 'src/app/shared/models/misurazione-consulto-stato-tipo';
import { MisurazioneStatoTipo as MisurazioneConsultoStatoTipo } from 'src/app/shared/models/misurazione/misurazione-stato-tipo';
import { MisurazioniConsulto } from 'src/app/shared/models/misurazioni-consulto';
import { Notifica } from '../../models/notifica';
import { RestBaseService } from './rest-base.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultoService extends RestBaseService {
  constructor() { super('consulto'); }

  /**
   * Inserisce la richiesta di consulto per una lista di misurazioni del paziente
   * @param richiestaConsulto richiesta di consulto da generare 
   * @returns true se consulto inserito correttamente oppure @see ApiResponse in caso di errore
   */
  public async creaNuovoConsulto(richiestaConsulto: MisurazioniConsulto): Promise<ApiResponse<boolean>> {
    if (!richiestaConsulto) throw Error('Richiesta consulto deve essere diversa da null' + this.contactITMsg);
    if (!richiestaConsulto.destinatario) throw Error('Richiesta consulto deve avere un destinatario.' + this.contactITMsg);
    if (!richiestaConsulto.misurazioni) throw Error('Richiesta consulto deve avere delle misurazioni allegate.' + this.contactITMsg);

    const misurazioniIds: number[] = [];
    for (let mis of richiestaConsulto.misurazioni) {
      misurazioniIds.push(mis.id);
    }
    const body: any = {};
    body['noteRichiedente'] = richiestaConsulto.noteRichiedente;
    body['destinatarioId'] = richiestaConsulto.destinatario.id;
    body['misurazioni'] = misurazioniIds;
    body['pazienteId'] = richiestaConsulto.pazienteId;

    const requestUrl: string = `${this.SERVICE_BASE_URL}`;
    return this.post<boolean>(requestUrl, body);
  }

  /**
   * Lista delle richieste di consulto rivolte ad un determinato utente (medico specialista)
   * @param utenteId id dell'utente a cui sono stati richiesti i consulti
   * @param inizioPeriodo data inizio del periodo di filtro
   * @param finePeriodo data fine del periodo di filtro
   * @param stato del consulto
   * @returns lista delle richieste di consulto indirizzate all'utente specificato oppure @see ApiResponse in caso di errore
   */
  public async getConsultiByDestinatario(utenteId: number, inizioPeriodo: Date, finePeriodo: Date, stato?: MisurazioneConsultoStatoTipo): Promise<ApiResponse<MisurazioniConsulto[]>> {
    if (!utenteId) throw Error('L\'id dell\'utente non e\' presente.' + this.contactITMsg);
    if (!inizioPeriodo || !finePeriodo) throw Error('Specificare il periodo delle richieste di consulto.' + this.contactITMsg);

    const inizio: string = DateTime.fromJSDate(inizioPeriodo).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    const fine: string = DateTime.fromJSDate(finePeriodo).toFormat("yyyy-MM-dd'T'HH:mm:ss'Z'");
    const statoId: number | undefined = stato?.id;
    const requestUrl: string = `${this.SERVICE_BASE_URL}destinatario/${utenteId}?da_data=${inizio}&a_data=${fine}` + (statoId ? `&stato=${statoId}` : '');
    return this.get<MisurazioniConsulto[]>(requestUrl);
  }

  /**
   * Quanti consulti non letti sono presenti per un determinato paziente?
   * @param pazienteId id del paziente per cui cercare i consulti non vistati
   */
  public async getConsultiNonVistiByPaziente(pazienteId: number): Promise<ApiResponse<Notifica>> {
    if (!pazienteId) throw Error('Indicare l\'id del paziente.' + this.contactITMsg);

    const requestUrl: string = `${this.SERVICE_BASE_URL}paziente/${pazienteId}/consultiNonLetti`;
    return this.get<Notifica>(requestUrl);
  }

    /**
   * Quante richieste di consulto non lette sono presenti per un determinato specialista?
   * @param addettoId id dell'addetto che ha ricevuto le richieste di consulto
   */
     public async getConsultiNuoviByDestinatario(addettoId: number): Promise<ApiResponse<Notifica>> {
      if (!addettoId) throw Error('Indicare l\'id del medico che ha ricevuto i consulti.' + this.contactITMsg);
  
      const requestUrl: string = `${this.SERVICE_BASE_URL}destinatario/${addettoId}/consultiNonLetti`;
      return this.get<Notifica>(requestUrl);
    }

  /**
   * Ricerca un consulto per id
   * @param consultoId id del consulto da cercare
   * @returns consulto con l'id richiesto oppure @see ApiResponse in caso di errore
   */
  public async getConsultoById(consultoId: number): Promise<ApiResponse<MisurazioniConsulto>> {
    if (!consultoId) throw Error('Indicare l\'id del consulto richiesto.' + this.contactITMsg);

    const requestUrl: string = `${this.SERVICE_BASE_URL}${consultoId}`;
    return this.get<MisurazioniConsulto>(requestUrl);
  }

  /**
   * Ottiene tutti i tipi degli stati dei consulti
   * @returns lista tipi degli stati dei consulti (anagrafica) oppure @see ApiResponse in caso di errore
   */
  public async getStatoTipiConsulto(): Promise<ApiResponse<MisurazioneConsultoStatoTipo[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}stati_anag`;
    return this.get<MisurazioneConsultoStatoTipo[]>(requestUrl, true);
  }

  /**
   * Cerca il tipo dello stato di un consulto per codice
   * @param consultoStatoTipo codice dello stato del consulto da cercare
   * @returns tipo dello stato di un consulto corrispondente oppure @see ApiResponse in caso di errore
   */
  public async getStatoTipoConsultoByCodice(consultoStatoTipo: MisurazioneConsultoStatoTipi): Promise<ApiResponse<MisurazioneConsultoStatoTipo>> {
    if (!consultoStatoTipo) throw Error('Indicare il codice del tipo dello stato del consulto richiesto.' + this.contactITMsg);

    const response = await this.getStatoTipiConsulto();
    if (!response || response.isError()) return ApiResponse.createErrorWithMessage(response?.getErrorMessage() ?? 'Non trovato');

    const data: MisurazioneConsultoStatoTipo[] = response.data ?? [];
    const statoTipo: MisurazioneConsultoStatoTipo | undefined = data.find(s => s.codice === consultoStatoTipo);
    return statoTipo ? ApiResponse.createOkWithData(statoTipo) : ApiResponse.createErrorWithMessage('Non trovato');
  }

  /**
   * Cerca il tipo dello stato di un consulto per id
   * @param consultoStatoTipoId id del tipo dello stato del consulto
   * @returns MisurazioneConsultoStatoTipo oppure @see ApiResponse in caso di errore
   */
  public async getStatoTipoConsultoById(consultoStatoTipoId: number): Promise<ApiResponse<MisurazioneConsultoStatoTipo>> {
    if (!consultoStatoTipoId) throw Error('Indicare l\'id del tipo dello stato del consulto richiesto.' + this.contactITMsg);

    const requestUrl: string = `${this.SERVICE_BASE_URL}stato/${consultoStatoTipoId}`;
    return this.get<MisurazioneConsultoStatoTipo>(requestUrl);
  }

  /**
 * Inserisce la richiesta di consulto per una lista di misurazioni del paziente
 * @param consulto richiesta di consulto da generare 
 * @returns true se consulto inserito correttamente oppure @see ApiResponse in caso di errore
 */
  public async valutaConsulto(consulto: MisurazioniConsulto): Promise<ApiResponse<boolean>> {
    if (!consulto) throw Error('Richiesta consulto deve essere diversa da null' + this.contactITMsg);

    const body: any = {};
    body['consulto_id'] = consulto.id;
    body['noteDestinatario'] = consulto.noteDestinatario;
    body['giudizioId'] = consulto.giudizioId;

    const requestUrl: string = `${this.SERVICE_BASE_URL}${consulto.id}/valuta`;
    return this.post<boolean>(requestUrl, body);
  }
}
