import { IEquatable } from "./iequatable";

export abstract class ModelBase<T extends ModelBase<T>> implements IEquatable<T>{
  public constructor(init?: Partial<T>) {
    Object.assign(this, init);
  }

  public equals(other: T): boolean {
    if (!other) return false;
    return JSON.stringify(this) === JSON.stringify(other);
  }
}