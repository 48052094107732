export class StringUtils {
  private static readonly CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  private static readonly ALPHA_NUMBER_UNDERSCORE = StringUtils.CHARS + '_';

  /**
   * Genera stringa casuale con caratteri fra a-z0-9 e underscore
   * @param length lunghezza della stringa
   * @returns stringa casuale di lunghezza richiesta
   */
  public static randomAlphaNumberLowercaseWithUnderscore(length: number = 1): string {
    const chars = StringUtils.ALPHA_NUMBER_UNDERSCORE.toLowerCase();
    let retval: string = '';
    const charsLength: number = chars.length;
    for (let i = 0; i < length; i++) {
      retval += chars.charAt(Math.floor(Math.random() * charsLength));
    }
    return retval;
  }
}
