import { Injectable } from '@angular/core';
import { Utente } from 'src/app/shared/models/utente/utente';
import { LoggerManager } from 'typescript-logger';
import { AuthenticatedUser } from '../models/authenticated-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  private readonly log = LoggerManager.create('TokenStorageService');

  private readonly ACCESS_TOKEN_KEY: string = 'auth-token';
  private readonly USER_KEY: string = 'auth-user';

  public saveAccessToken(token: string): boolean {
    try {
      /*       let tokenObj;
          if (typeof jwtToken === "string") {
            tokenObj = JSON.parse(jwtToken);
          } else {
            tokenObj = jwtToken;
          } */
      window.sessionStorage.setItem(this.ACCESS_TOKEN_KEY, token);
      return true;
    } catch (error) {
      this.log.error('Impossibile salvare access token nello storage del client. ' + error);
      return false;
    }
  }

  public getAccessToken(): string | undefined {
    return window.sessionStorage.getItem(this.ACCESS_TOKEN_KEY) ?? undefined;
  }

  public saveUser(user: AuthenticatedUser): boolean {
    try {
      window.sessionStorage.setItem(this.USER_KEY, JSON.stringify(user));
      return true;
    } catch (error) {
      this.log.error('Impossibile salvare utente nello storage del client. ' + error);
      return false;
    }
  }

  public getUser(): Utente | null {
    const user = window.sessionStorage.getItem(this.USER_KEY);
    if (!user) return null;

    return JSON.parse(user);
  }

  public clear(): void {
    window.sessionStorage.clear();
  }
}
