import { Component, EventEmitter, Input, Output } from '@angular/core';
/**
 * Componente per la selezione di una data con relativa icona
 * Parametri:
 *        [(mainData)]: Date, data selezionata. Obbligatoria
 *        [minData]: Date, data minima per la selezione. Opzionale
 *        [maxData]: Date, data minima per la selezione. Opzionale
 *        [formatoData]: stringa, formato della data mostrata (vedere component calendar modificando la versione di primeng https://www.primefaces.org/primeng-v13/calendar). Opzionale
 */
@Component({
  selector: 'date-selection',
  templateUrl: './date-selection.component.html',
  styleUrls: ['./date-selection.component.css']
})
export class DateSelectionComponent{
  @Input() public disabled: boolean = false;
  @Input() public minData: Date = new Date(2000, 0, 1);
  @Input() public maxData: Date = new Date(2050, 11, 31);
  @Input() public formatoData: string = "dd/mm/yy";

  @Input() public mainData!: Date;
  @Output() mainDataChange: EventEmitter<Date> = new EventEmitter();

  public onDataChange():void{
    this.mainDataChange.emit(this.mainData);
  }
}
