import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

// PRIMENG
import { InputSwitchModule } from 'primeng/inputswitch';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { MenuModule } from 'primeng/menu';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabViewModule } from 'primeng/tabview';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { PasswordModule } from 'primeng/password';

import { ReqAnyAuthsDirective } from './directives/req-any-auths.directive';
import { ReqAllAuthsDirective } from './directives/req-all-auths.directive';
import { RestrictedListSelectionComponent } from './components/restricted-list-selection/restricted-list-selection.component';
import { FixedContainerComponent } from './components/fixed-container/fixed-container.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { TableActionButtonComponent } from './components/buttons/table-action-button/table-action-button.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { OkButtonComponent } from './components/buttons/ok-button/ok-button.component';
import { IconBaseButtonComponent } from './components/buttons/icon-base-button/icon-base-button.component';
import { CancelButtonComponent } from './components/buttons/cancel-button/cancel-button.component';
import { BaseViewEditComponent } from './components/base-view-edit/base-view-edit.component';
import { WeekDaySelectorComponent } from './components/week-day-selector/week-day-selector.component';
import { DeleteButtonComponent } from './components/buttons/delete-button/delete-button.component';
import { ForwardButtonComponent } from './components/buttons/forward-button/forward-button.component';
import { BackButtonComponent } from './components/buttons/back-button/back-button.component';
import { CloseButtonComponent } from './components/buttons/close-button/close-button.component';
import { CommonModule } from '@angular/common';
import { HasAnyProfileDirective } from './directives/has-any-profile.directive';
import { DateSelectionComponent } from './components/selection/date-selection/date-selection.component';
import { SearchButtonComponent } from './components/buttons/search-button/search-button.component';
import { IconLabelComponent } from './components/icon-label/icon-label.component';
import { AddButtonComponent } from './components/buttons/add-button/add-button.component';


@NgModule({
  declarations: [
    ReqAnyAuthsDirective,
    ReqAllAuthsDirective,
    HasAnyProfileDirective,

    WeekDaySelectorComponent,
    RestrictedListSelectionComponent,
    FixedContainerComponent,
    TableHeaderComponent,
    TableActionButtonComponent,
    PageTitleComponent,
    OkButtonComponent,
    IconBaseButtonComponent,
    CancelButtonComponent,
    BaseViewEditComponent,
    DeleteButtonComponent,
    ForwardButtonComponent,
    BackButtonComponent,
    CloseButtonComponent,
    DateSelectionComponent,
    SearchButtonComponent,
    IconLabelComponent,
    AddButtonComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    CalendarModule,
    ButtonModule,
    TableModule,
    DialogModule,
    StepsModule,
    MenuModule,
    InputTextModule,
    OverlayPanelModule,
    TooltipModule,
    DynamicDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    DropdownModule,
    InputSwitchModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    InputMaskModule,
    AutoCompleteModule,
    PasswordModule
  ],
  exports: [
    CommonModule,
    MultiSelectModule,
    CalendarModule,
    ButtonModule,
    TableModule,
    DialogModule,
    StepsModule,
    MenuModule,
    InputTextModule,
    OverlayPanelModule,
    TooltipModule,
    DynamicDialogModule,
    FormsModule,
    ReactiveFormsModule,
    TabViewModule,
    DropdownModule,
    InputSwitchModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    InputTextareaModule,
    InputMaskModule,
    AutoCompleteModule,
    PasswordModule,

    // DIRECTIVE
    ReqAnyAuthsDirective,
    ReqAllAuthsDirective,
    HasAnyProfileDirective,

    // COMPONENT
    WeekDaySelectorComponent,
    RestrictedListSelectionComponent,
    FixedContainerComponent,
    TableHeaderComponent,
    PageTitleComponent,
    TableActionButtonComponent,
    OkButtonComponent,
    CancelButtonComponent,
    BaseViewEditComponent,
    DeleteButtonComponent,
    ForwardButtonComponent,
    BackButtonComponent,
    CloseButtonComponent,
    DateSelectionComponent,
    SearchButtonComponent,
    IconLabelComponent,
    AddButtonComponent
  ]
})
export class SharedModule { }