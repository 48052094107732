import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';

@Component({
  selector: 'fixed-container',
  templateUrl: './fixed-container.component.html',
  styleUrls: ['./fixed-container.component.css']
})
export class FixedContainerComponent implements OnInit {
  
  @Input() style: { [key: string]: string } = {};

  public constructor() {}

  ngOnInit() {
    for (let key in this.style) {
      document.getElementById("fixed-container")!.style.setProperty(key, this.style[key]);
    }
  }
}
