import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * Componente base per i componenti che offrono il classico dialog dove l'utente
 * puo modificae dei dati e salvare o annullare le modifiche
 */
@Component({
  selector: 'base-view-edit',
  template: ''
})
export class BaseViewEditComponent<T>{
  /**
   * Template contente i dati da mostrare
   */
  @ContentChild('dataFormTemplate', { static: false }) dataFormTemplate!: TemplateRef<any>;
  /**
       * Callback per annullare le modifiche effettuate ai dati
       */
  @Output() public annullaModifiche: EventEmitter<any> = new EventEmitter();
  /**
   * Dati per il modello principale del component
   */
  @Input() public data!: T;
  /**
   * Dati per il modello principale del component
   */
  @Output() public dataChange: EventEmitter<T> = new EventEmitter<T>();
  public dataChanged: boolean = false;
  /**
   * Callback per salvare i dati
   */
  @Output() public salvaModifiche: EventEmitter<T> = new EventEmitter<T>();

  public onDataChange(): void {
    this.dataChange.emit(this.data);
    this.dataChanged = true;
  }
}
