/*  const PROTOCOL: string = "http";
const API_SERVER_BASE_URL: string = "127.0.0.1";
const API_SERVER_PORT: number = 9000;
export const AUTH_SERVER_BASE_URL: string = `${PROTOCOL}://${API_SERVER_BASE_URL}:${API_SERVER_PORT}/api/auth/`;
export const API_BASE_URL: string = `${PROTOCOL}://${API_SERVER_BASE_URL}:${API_SERVER_PORT}/wingest-api/v100/proxmed/`;  */

const PROTOCOL: string = "https";
const API_SERVER_BASE_URL: string = "medhub.sidera.cloud";
const API_SERVER_PORT: number = 443;
export const AUTH_SERVER_BASE_URL: string = `${PROTOCOL}://${API_SERVER_BASE_URL}:${API_SERVER_PORT}/api/auth/`;
export const API_BASE_URL: string = `${PROTOCOL}://${API_SERVER_BASE_URL}:${API_SERVER_PORT}/wingest-api/v100/proxmed/`;