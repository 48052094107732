import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, Output } from '@angular/core';

/**
 * Componente per la navigazione fra elementi tramite selezione con dropdown o pulsanti avanti e indietro
 */
@Component({
  selector: 'restricted-list-selection',
  templateUrl: './restricted-list-selection.component.html',
  styleUrls: ['./restricted-list-selection.component.css'],
})
export class RestrictedListSelectionComponent implements OnChanges {
  public canGoBack: boolean = true;
  public canGoForward: boolean = true;
  @Input() disabled: boolean = false;
  @Input() selectionList: Date[] = [];
  @Input() selectedElement!: Date;
  @Output() selectedElementChange = new EventEmitter<Date>();
  private selectedElementIndex: number = -1;

  public ngOnChanges(changes: SimpleChanges): void {
    this.checkMovementButtons();
    if (changes.selectedElement && changes.selectedElement.firstChange) this.notifySelectionChanged();
  }

  public dropdownSelectionChange(): void {
    this.checkMovementButtons();
    this.notifySelectionChanged();
  }

  /**
   * Naviga all'elemento successivo della lista se non gia raggiunta la fine
   */
  public moveForward(): void {
    this.checkMovementButtons();
    if (!this.canGoForward) return;
    // sposta al prossimo elemento
    this.selectedElement = this.selectionList[++this.selectedElementIndex];
    this.canGoBack = true;
    this.canGoForward = this.selectedElementIndex < this.selectionList.length - 1;
    this.notifySelectionChanged();
  }

  /**
   * Naviga all'elemento precedente della lista se non gia raggiunto l'inizio
   */
  public moveBack(): void {
    this.checkMovementButtons();
    if (!this.canGoBack) return;
    // sposta al prossimo elemento
    this.selectedElement = this.selectionList[--this.selectedElementIndex];
    this.canGoForward = true;
    this.canGoBack = this.selectedElementIndex > 0;
    this.notifySelectionChanged();
  }

  private notifySelectionChanged(): void {
    this.selectedElementChange.emit(this.selectedElement);
  }

  /**
   * Verifica se i pulsanti avanti e indietro sono attivabili o meno
   */
  private checkMovementButtons(): void {
    this.selectionList = this.selectionList ?? [];
    this.canGoForward = this.canGoBack = this.selectionList.length !== 0;
    if (!this.selectedElement) {
      this.canGoForward = this.canGoBack = false;
    }
    else {
      const millis = this.selectedElement.getTime();
      this.selectedElementIndex = this.selectionList.findIndex(element => element.getTime() === millis);
      this.canGoBack = this.selectedElementIndex > 0;
      this.canGoForward = this.selectedElementIndex < this.selectionList.length - 1;
    }
  }
}