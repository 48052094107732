import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

/**
 * Pulsante chiudi con icona
 */
@Component({
  selector: 'close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.css']
})
export class CloseButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
    this.value = 'Chiudi';
  }
}
