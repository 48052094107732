import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'week-day-selector',
  templateUrl: './week-day-selector.component.html',
  styleUrls: ['./week-day-selector.component.css']
})
export class WeekDaySelectorComponent {
  public disableDay: string = '';
  public daysList: string[] = ['Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato', 'Domenica'];
  public selectedDay: string = '';


  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
    this.disableDay = config.data.disableDay;
  }

  public sendSelectedDay() {
    this.ref.close(this.selectedDay);
    this.reset()
  }

  private reset() {
    this.disableDay = '';
    this.selectedDay = '';
  }
}



