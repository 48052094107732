<div class="col-12">
  <div class="card-container">
    <img [src]="logoProxmed" alt="Proxmed" class="logo-proxmed" width="350px" />
    <div class="card2">
      <img [src]="logoMedPortal" alt="Proxmed" class="logo-medportal" />

      <form *ngIf="!isLoggedIn" (ngSubmit)="loginForm.form.valid && onSubmit()" #loginForm="ngForm" novalidate
        class="form-container">

        <!-- USERNAME -->
        <div>
          <input type="text" class="form-control" #username="ngModel" name="username" [(ngModel)]="model.username"
            required placeholder="username" />
          <div class="alert alert-danger" role="alert" *ngIf="username.errors && loginForm.submitted">
            Username is required!
          </div>
        </div>

        <!-- PASSWORD -->
        <div>
          <input type="password" class="form-control" #password="ngModel" name="password" [(ngModel)]="model.password"
            required minlength="6" placeholder="password" />
          <div class="alert alert-danger" role="alert" *ngIf="password.errors && loginForm.submitted">
            <div *ngIf="password.errors.required">Password is required</div>
            <div *ngIf="password.errors.minlength">
              Password must be at least 6 characters
            </div>
          </div>
        </div>

        <!-- LOGIN -->
        <div class="form-group">
          <button class="btn login-btn btn-block">
            <i class="fas fa-sign-in-alt" style="margin-left: 5px;"></i>
            Login
          </button>
        </div>
        <div class="form-group">
          <div class="alert alert-danger" role="alert" *ngIf="loginForm.submitted && isLoginFailed">
            Login failed: {{ errorMessage }}
          </div>
        </div>
      </form>
    </div>
  </div>
</div>