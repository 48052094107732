<body>
  <div>
    <div class="preSetPlansScrollableDialog">
      <span *ngFor="let day of daysList" style="font-size: 20px;" (click)="selectedDay=day"
        [ngClass]="selectedDay==day ? 'btn btn-primary' : 'form-control'"
        [ngStyle]="day==disableDay ? { 'pointer-events' : 'none', 'opacity':'0.5'} : {}"
        style="width: 100%; text-align: left;">{{day}}</span>
    </div>
  </div>
  <span style="color: red;">
    <i class="fas fa-exclamation-circle" style="margin-right: 5px;"></i>
    Attenzione, confermando la copia i dati presenti in {{disableDay}}
    verranno sovrascritti
  </span>
  <div style="text-align: center;">
    <button class="btn btn-success" style="margin-top: 10px;" [disabled]="selectedDay==''"
      (click)="sendSelectedDay()">Conferma copia</button>
  </div>
</body>