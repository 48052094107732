import { Utente } from "./utente";

/**
 * Utente con nome e cognome dell'addetto
 */
export class UtenteNominativo extends Utente {
  public cognome!: string;
  public nome!: string;

  public constructor(init?: Partial<UtenteNominativo>) {
    super(init);
  }

  public equals(other: UtenteNominativo): boolean {
    return super.equals(other);
  }
}
