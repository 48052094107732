import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { UserAuthorization } from '../models/user-authorization';

/**
 * Direttiva per verificare che l'utente abbia tutte le autorizzazioni richieste
 */
@Directive({
  selector: '[reqAllAuths]'
})
export class ReqAllAuthsDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) { }

    @Input() set reqAllAuths(authorizations: UserAuthorization[]) {
      let condition = this.authService.hasAllAuthorizations(authorizations);
      if (condition) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
}
