import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { AUTH_SERVER_BASE_URL } from 'src/app/core/utils/app.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenStorageService } from './token-storage.service';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';
import { LoggerManager } from 'typescript-logger/build/loggerManager';
import { UserAuthorization } from 'src/app/shared/models/user-authorization';
import { lastValueFrom } from 'rxjs';
import { AuthenticatedUser } from '../models/authenticated-user';
import { UtenteNominativo } from 'src/app/shared/models/utente/utente-nominativo';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly TOKEN_REQUEST_URL: string = `${AUTH_SERVER_BASE_URL}signin`;
  private readonly jwtHelper = new JwtHelperService();
  private readonly log = LoggerManager.create('AuthService');

  @Output() public loggedChanged: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient,
    private tokenStorageService: TokenStorageService) { }

  public getAccessToken(): string | undefined {
    return this.tokenStorageService.getAccessToken();
  }

  public isTokenExpired(): boolean {
    const token : string | undefined = this.getAccessToken();
    if(!token) return true;

    return this.jwtHelper.isTokenExpired(token, 5);
  }

  public getUserData(): UtenteNominativo {
    if (!this.isLoggedIn()) return new UtenteNominativo({ id: 0, addettoId: 0 });

    const authUser: AuthenticatedUser = this.getUser();
    const user: UtenteNominativo = new UtenteNominativo({
      id: authUser.id,
      addettoId: authUser.addettoId,
      nome: authUser.name,
      cognome: authUser.surname,
      username: authUser.username
    });
    return user;
  }

  public getUserRole(): UtenteProfilo {
    if (!this.isLoggedIn()) throw Error('Ruolo utente non disponbile, utente non loggato');

    return this.getUser().role;
  }

  /**
 * Verifica se l'utente e' loggato ed ha almeno una delle autorizzazioni passate come parametro
 * @param userAuthorizations lista autorizzazioni da verificare.
 * @returns true: lista vuota, null, o se l'utente possiede almeno una delle autorizzazioni richieste, false altrimenti.
 */
  public hasAllAuthorizations(userAuthorizations: UserAuthorization | UserAuthorization[]): boolean {
    if (!this.isLoggedIn()) return false;
    if (!userAuthorizations) return true;

    const user: AuthenticatedUser = this.getUser();

    let hasAuthorizations: boolean = false;
    if (Array.isArray(userAuthorizations)) {
      // l'utente ha tutte le autorizzazioni richieste?
      hasAuthorizations = userAuthorizations.every(reqAuth =>
        user.authorizations.some(userAuth => userAuth.equals(reqAuth)));
    }
    else {
      // fra i ruoli dell'utente c'è quello richiesto?
      hasAuthorizations = user.authorizations.some(element => element.equals(userAuthorizations));
    }
    return hasAuthorizations;
  }

  /**
   * Verifica se l'utente e' loggato ed ha almeno una delle autorizzazioni passate come parametro
   * @param userAuthorizations lista autorizzazioni da verificare.
   * @returns true: lista vuota, null, o se l'utente possiede almeno una delle autorizzazioni richieste, false altrimenti.
   */
  public hasAnyAuthorizations(userAuthorizations: UserAuthorization | UserAuthorization[]): boolean {
    if (!this.isLoggedIn()) return false;
    if (!userAuthorizations) return true;

    const user: AuthenticatedUser = this.getUser();

    let hasAuthorization: boolean = false;
    if (Array.isArray(userAuthorizations)) {
      for (let authorization of userAuthorizations) {
        // l'utente ha almeno una autorizzazione richiesta?
        hasAuthorization = user.authorizations.some(element => element.equals(authorization));
        if (hasAuthorization) break;
      }
    }
    else {
      // fra i ruoli dell'utente c'è quello richiesto?
      hasAuthorization = user.authorizations.some(element => element.equals(userAuthorizations));
    }
    return hasAuthorization;
  }

  public hasAnyRole(userRoles: UtenteProfilo | UtenteProfilo[]): boolean {
    if (!this.isLoggedIn()) return false;
    if (!userRoles) return true;

    const user: AuthenticatedUser = this.getUser();

    let hasAnyRole: boolean = false;
    if (Array.isArray(userRoles)) {
      for (let role of userRoles) {
        // l'utente ha almeno una autorizzazione richiesta?
        hasAnyRole = user.role.nome === role.nome;
        if (hasAnyRole) break;
      }
    }
    else {
      // fra i ruoli dell'utente c'è quello richiesto?
      hasAnyRole = user.role.nome === userRoles.nome;
    }
    return hasAnyRole;
  }

  public hasRole(userRole: UtenteProfilo): boolean {
    return this.hasAnyRole(userRole);
  }

  public isLoggedIn(): boolean {
    return !!this.tokenStorageService.getAccessToken();
  }

  public async login(username: string, password: string): Promise<boolean> {
    const isLoggedIn: boolean = await this.doLogin(username, password);
    this.loggedChanged.emit(isLoggedIn);
    return isLoggedIn;
  }

  public logout(): void {
    this.tokenStorageService.clear();
    this.loggedChanged.emit(false);
    window.location.reload();
  }

  private createBody(username: string, password: string): string {
    const body = { username: username, password: password };
    return JSON.stringify(body);
  }

  private createHeaders(): HttpHeaders {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json')
      .append('societa', '1')
      .append('tenant', 'proxmed');
    return headers;
  }

  private async doLogin(username: string, password: string): Promise<boolean> {
    try {
      const body = this.createBody(username, password);
      const headers = this.createHeaders();
      const response: any = await lastValueFrom(this.http.post(this.TOKEN_REQUEST_URL, body, { headers: headers }))
        .catch(error => { throw Error(JSON.stringify(error)); });

      if (!response) throw Error('Token di accesso non trovato');

      const access_token: any = this.jwtHelper.decodeToken(response['accessToken']);
      if (!access_token) throw Error('Token di accesso non trovato');

      const user: AuthenticatedUser = this.extractUserData(access_token);

      let salvatoStorage = this.tokenStorageService.saveAccessToken(response['accessToken']);
      salvatoStorage = salvatoStorage && this.tokenStorageService.saveUser(user);
      if (!salvatoStorage) alert('Impossibile salvare i dati di accesso.\n'
        + 'Permettere l\'accesso allo storage locale.\n'
        + 'Per Firefox guardare a https://support.mozilla.org/en-US/kb/storage');

      return salvatoStorage;
    } catch (error) {
      const mex: string = 'Login fallito. ' + error;
      this.log.error(mex);
      alert(mex);
      return false;
    }
  }

  /**
   * Estrapola i dati dell'utente dal token
   * @param jwtToken token da cui estrarre i dati dell'utente(come oggetto javascript)
   * @returns i dati dell'utente oppure Error nel caso in cui alcuni dati necessari non sono presenti nel token
   */
  private extractUserData(jwtToken: string | object): AuthenticatedUser {
    const tokenObj = typeof jwtToken === "string" ? JSON.parse(jwtToken) : jwtToken;

    const id: number = Number.parseInt(tokenObj['userid']);
    const addettoId: number = Number.parseInt(tokenObj['addettoid']);
    if (!id) throw new Error('Il token non contiene id utente');
    if (!addettoId) throw new Error('Il token non contiene id addetto');
    const user: AuthenticatedUser = new AuthenticatedUser(id, addettoId);

    const autorizations: string[] = tokenObj['authorizations'];
    if (!autorizations) throw new Error('Il token non contiene le autorizzazioni utente');

    const userRole: string = tokenObj['role'];
    if (!userRole) throw new Error('Il token non contiene il tipo utente (medico, inf, ecc)');
    user.role = UtenteProfilo.parse(userRole);

    let userAuthorizations: UserAuthorization[] = [];
    autorizations.forEach(element => userAuthorizations.push(UserAuthorization.parse(element)));
    user.authorizations = userAuthorizations;

    user.username = tokenObj['username'] ?? 'Username indisponibile';
    user.name = tokenObj['name'] ?? 'Name indisponibile';
    user.surname = tokenObj['surname'] ?? 'Name indisponibile';

    return user;
  }

  private getUser(): AuthenticatedUser {
    let token: string | null | undefined = this.tokenStorageService.getAccessToken();
    if (!token) throw Error('Dati utente non disponibili, utente non loggato');

    token = this.jwtHelper.decodeToken(token);
    if (!token) throw Error('Dati utente non disponibili, token non valido');;

    const user: AuthenticatedUser | null = this.extractUserData(token);
    return user;
  }
}