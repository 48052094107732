import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

@Component({
  selector: 'back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.css']
})
export class BackButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
  }
}

