<div id="app">
  <div>
    <navbar></navbar>
  </div>

  <div class="mainContainer">
    <router-outlet></router-outlet>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</div>