import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { UtenteService } from 'src/app/core/services/rest/utente.service';
import { Utente } from 'src/app/shared/models/utente/utente';

/**
 * Dialog che permette all'utente di cambiare la prorpia password inserendo la vecchia e la nuova password
 */
@Component({
  selector: 'utente-cambio-password',
  templateUrl: './utente-cambio-password.dialog.html',
  styleUrls: ['./utente-cambio-password.dialog.css']
})
export class UtenteCambioPasswordDialog implements OnInit {
  /**
   * Tiene traccia se i dati del form sono stati cambiati
   */
  private dataChanged: boolean = false;
  /**
   * Sottoscrizione alle modifiche dei campi del form utente
   */
  private utenteFormChangesSubscription?: Subscription;
  /**
   * E' stato modificato almeno un campo?
   * Viene emesso solo al primo cambiamento per motivi di performance
   */
  @Output() public isChanged = new EventEmitter<boolean>();
  public utente!: Utente;
  private readonly PASSWORD_CURRENT = 'passwordCorrente';
  private readonly PASSWORD_NEW = 'nuovaPassword';

  /**
   * Form principale contenente tutti i campi
   */
  public utenteForm!: FormGroup;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private userService: UtenteService) {
    this.utente = config.data?.user;
    if (!this.utente) throw Error('Prametro di input utente non valido');
  }

  public ngOnDestroy(): void {
    this.unsubscribeFromFormChanges();
  }

  public ngOnInit(): void {
    this.initFormDatiUtente();
  }
  public getCurrentPassword(): string {
    return this.utenteForm.get(this.PASSWORD_CURRENT)?.value;
  }

  public getNewPassword(): string {
    return this.utenteForm.get(this.PASSWORD_NEW)?.value;
  }

  public async closeAndSaveChanges(): Promise<void> {
    if (!this.utenteForm) return;
    if (this.utenteForm.invalid) return;
    const currentPassword: string = this.getCurrentPassword();
    const newPassword: string = this.getNewPassword();

    const response = await this.userService.changeUserPassword(this.utente, currentPassword, newPassword);
    if (response.isError()) {
      alert('Impossibile cambiare la password: ' + response.getErrorMessage());
      return;
    }

    alert('Password cambiata con successo');
    this.ref.close(true);
  }

  public closeAndAbortChanges(): void {
    this.ref.close(false);
  }

  public passwordEqualityValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.dirty) return null;
      const nuovaPass = String(control.get('nuovaPassword')?.value);
      const nuovaPassConferma = String(control.get('nuovaPasswordConferma')?.value);

      // Valido solo se le due nuove password sono inserite ed uguali fra loro
      if (nuovaPass && nuovaPassConferma && nuovaPassConferma === nuovaPass) return null;

      return { passwordEquality: { value: false } };
    };
  }

  private initFormDatiUtente(): void {
    this.utenteForm = this.formBuilder.group({
      username: [{ value: this.utente.username, disabled: true }],
      [this.PASSWORD_CURRENT]: ['', [Validators.required, Validators.minLength(5)]],
      [this.PASSWORD_NEW]: ['', [Validators.required, Validators.minLength(5)]],
      nuovaPasswordConferma: ['', [Validators.required, Validators.minLength(5)]]
    }, { updateOn: 'change', validators: this.passwordEqualityValidator() });
    this.utenteFormChangesSubscription = this.utenteForm.valueChanges.subscribe(change => { this.onFormChanges() });
  }

  private onFormChanges(): void {
    if (!this.dataChanged) {
      this.dataChanged = true;
      this.isChanged.emit(true);
      this.unsubscribeFromFormChanges();
    }
  }

  private unsubscribeFromFormChanges(): void {
    if (this.utenteFormChangesSubscription) {
      this.utenteFormChangesSubscription.unsubscribe();
    }
  }
}
