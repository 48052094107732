
export class StaticResourceService {
  public static getPathLogoProxmed(): string {
    return 'assets/images/logo_proxmed.png';
  }

  public static getPathLogoMedPortal(): string {
    return 'assets/images/logo_medportal.png';
  }

  public static getPathLogoProxmedMedportal(): string {
    return 'assets/images/logo_proxmed_medportal_uniti.png';
  }
}
