import { Component, Input } from '@angular/core';

/**
 * Componente per il titolo di una pagina
 * (es. lista pazienti in carico, lista pazienti in amministrazione, ecc)
 */
@Component({
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.css']
})
export class PageTitleComponent {
  @Input() icon?: string;
  @Input() color?: string;
  @Input() value: string = '';

}
