import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { ConfirmationService } from 'primeng/api';
import { UtenteCambioPasswordDialog } from './dialogs/utente-cambio-password/utente-cambio-password.dialog';
import { MenubarModule } from 'primeng/menubar';
import { UtenteInfoDialog } from './dialogs/utente-info/utente-info/utente-info.dialog';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    FooterComponent,
    NavbarComponent,
    UtenteCambioPasswordDialog,
    UtenteInfoDialog,
    LoginComponent
  ],
  imports: [
    RouterModule,
    HttpClientModule,
    SharedModule,
    MenubarModule 
  ],
  exports: [
    RouterModule,
    MenubarModule,

    FooterComponent,
    NavbarComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ConfirmationService
  ]
})
export class CoreModule { }
