import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/auth/auth.service';
import { StaticResourceService } from 'src/app/core/services/static-resource.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public readonly logoProxmed: string = StaticResourceService.getPathLogoProxmed();
  public readonly logoMedPortal: string = StaticResourceService.getPathLogoMedPortal();

  public errorMessage = '';
  public readonly model: any = { username: null, password: null };
  public isLoggedIn = false;
  public isLoginFailed = false;

  constructor(private route: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {
  }

  public ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
  }

  public async onSubmit(): Promise<void> {
    const username: string = this.model.username?.trim();
    const password: string = this.model.password?.trim();

    const isLoggedIn: boolean = await this.authService.login(username, password);
    if (!isLoggedIn) {
      this.errorMessage = 'Login fallito';
      this.isLoginFailed = true;
      this.isLoggedIn = false;
      return;
    }

    this.isLoginFailed = false;
    this.isLoggedIn = true;
    this.route.navigate(['/home']);
  }

  public reloadPage(): void {
    window.location.reload();
  }
}
