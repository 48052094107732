<div id="header-box">

  <div id="header-filters-container" *ngIf="filters || filtersButtons">
    
    <div id="header-filters" *ngIf="filters">
      <ng-container *ngTemplateOutlet="filters;"></ng-container>
    </div>

    <div id="header-filters-buttons" *ngIf="filtersButtons">
      <ng-container *ngTemplateOutlet="filtersButtons;"></ng-container>
    </div>

  </div>

  <div id="header-buttons" *ngIf="leftButtons || centerButtons || rightButtons">

    <div id="header-left-buttons" *ngIf="leftButtons">
      <ng-container *ngTemplateOutlet="leftButtons;"></ng-container>
    </div>

    <div id="header-center-buttons" *ngIf="centerButtons">
      <ng-container *ngTemplateOutlet="centerButtons;"></ng-container>
    </div>

    <div id="header-right-buttons" *ngIf="rightButtons">
      <ng-container *ngTemplateOutlet="rightButtons;"></ng-container>
    </div>

  </div>

</div>