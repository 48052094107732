import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './core/components/login/login.component';
import { AuthGuardService } from './core/guards/auth-guard.service';
import { HomeRoleRedirectGuardServiceGuard } from './core/guards/home-role-redirect-guard-service.guard';


const routes: Routes = [
  { path: 'login', component: LoginComponent },

  /** 
     * RUOLI UTENTE
     * inserire un percorso per ogni ruolo in UserRole(medico, infermiere, ecc), usare il codice in lowercase
    */
  {
    path: 'medico', canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/profiles/medico/medico.module').then(m => m.MedicoModule)
  },
  {
    path: 'infermiere', canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/profiles/infermiere/infermiere.module').then(m => m.InfermiereModule)
  },
  {
    path: 'amministratore', canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/profiles/amministratore/amministratore.module').then(m => m.AmministratoreModule)
  },
  {
    path: 'supervisore', canActivate: [AuthGuardService],
    loadChildren: () => import('./modules/profiles/supervisore/supervisore.module').then(m => m.SupervisoreModule)
  },

  { // percorso base usato come fall back
    path: 'home', canActivate: [AuthGuardService, HomeRoleRedirectGuardServiceGuard],
    component: LoginComponent
  },
  {
    path: '**', canActivate: [AuthGuardService], component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }