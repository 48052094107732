import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

/**
 * Pulsante standard per azioni tabellari (pulsanti sopra la tabella)
 */
@Component({
  selector: 'table-action-button',
  templateUrl: './table-action-button.component.html',
  styleUrls: ['./table-action-button.component.css']
})
export class TableActionButtonComponent extends IconBaseButtonComponent {
}
