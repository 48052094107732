import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

/**
 * Pulsante ok con icona
 */
@Component({
  selector: 'ok-button',
  templateUrl: './ok-button.component.html',
  styleUrls: ['./ok-button.component.css']
})
export class OkButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
    this.value = 'Salva';
  }
}
