<body *ngIf="initializationEnded">
  <form [formGroup]="infoUtenteForm">
    <div style="display: flex; flex-direction: column; row-gap: 1.5rem; margin-top: 20px;">
      <div style="display: flex; flex-direction: row; column-gap: 2rem;">
        <span class="p-float-label">
          <input pInputText type="text" #username formControlName="username">
          <label for="username">Username</label>
        </span>
        <span class="p-float-label">
          <input pInputText type="text" #codiceFiscale formControlName="codiceFiscale">
          <label for="codiceFiscale">Codice fiscale</label>
        </span>
      </div>

      <div style="display: flex; flex-direction: row; column-gap: 2rem;">
        <span class="p-float-label">
          <input pInputText type="text" #nome formControlName="nome">
          <label for="nome">Nome</label>
        </span>
        <span class="p-float-label">
          <input pInputText type="text" #cognome formControlName="cognome">
          <label for="cognome">Cognome</label>
        </span>
      </div>

      <div style="display: flex; flex-direction: row; column-gap: 2rem;">
        <span class="p-float-label">
          <input pInputText type="tel" #telFisso formControlName="telFisso">
          <label for="telFisso">Tel. fisso</label>
        </span>
        <span class="p-float-label">
          <input pInputText type="tel" #telMobile formControlName="telMobile">
          <label for="telMobile">Cellulare</label>
        </span>
        <span class="p-float-label">
          <input pInputText type="mail" #email formControlName="email">
          <label for="email">Email</label>
        </span>
      </div>
    </div>
  </form>
  <div *ngIf="infoUtenteForm.touched && !infoUtenteForm.valid && infoUtenteForm.errors?.leastTelephoneContact"
    style="color: red; font-style: italic;">
    <label>Inserire almeno un contatto telefonico</label>
  </div>

  <!-- PULSANTI SALVA/ANNULLA -->
  <div class="button-container-centered">
    <p-button label="Salva" styleClass="button-action-default-save" (onClick)="closeAndSaveChanges()"
      [disabled]="!(infoUtenteForm.touched && this.infoUtenteForm.valid)" pTooltip="Salva le modifiche ai tuoi dati"
      tooltipPosition="top" icon="fa fa-check-circle" iconPos="left">
    </p-button>
    <p-button label="Annulla" (onClick)="closeAndAbortChanges()" styleClass="button-action-default-cancel"
      pTooltip="Annulla le modifiche eseguite ai tuoi dati" tooltipPosition="top" icon="fa fa-times-circle"
      iconPos="left"></p-button>
  </div>
</body>