import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { Utente } from 'src/app/shared/models/utente/utente';
import { lastValueFrom } from 'rxjs';
import { JsonConverter } from './helper/json-converter';
import { RestBaseService } from './rest-base.service';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';

@Injectable({
  providedIn: 'root'
})
export class UtenteService extends RestBaseService {
  constructor() { super('utente'); }

  public async changeUserPassword(user: Utente, oldPassword: string, newPassword: string): Promise<ApiResponse<boolean>> {
    const body = {
      id: user.id,
      vecchia_pwd: oldPassword,
      nuova_pwd: newPassword
    };
    const requestUrl: string = `${this.SERVICE_BASE_URL}changepwd`;
    try {
      const response = await lastValueFrom(this.http.put(requestUrl, body))
        .catch(error => { throw Error(JSON.stringify(error)) });

      if (!response) throw Error('Impossibile ottenere addetto, risposta null');
      let obj = JsonConverter.deserialize(response);
      return ApiResponse.create<boolean>(obj);
    }
    catch (error) {
      return ApiResponse.createErrorWithData(false, JSON.stringify(error));
    }
  }

  /**
   * Crea un nuovo infermiere e l'addetto collegato
   * @param utente nuovo da inserire
  */
  public createInfermiere(utente: Utente): Promise<ApiResponse<boolean>> {
    return this.doCreateUtente(utente, 'infermiere');
  }

 /**
  * Crea un nuovo medico e l'addetto collegato con eventuali specializzazioni
  * @param utente nuovo utente da inserire
  * @param isFamiglia viene inserito un medico di famiglia (true) o di struttura(false)?
  */
  public createMedico(utente: Utente, isFamiglia:boolean = false): Promise<ApiResponse<boolean>> {
    const utenteObj:any = utente;
    utenteObj['base'] = isFamiglia ? 1 : 0;
    return this.doCreateUtente(utente, 'medico');
 }

  /**
   * Crea un nuovo operatore e l'addetto collegato
   * @param utente nuovo da inserire
  */
  public createOperatore(utente: Utente): Promise<ApiResponse<boolean>> {
      return this.doCreateUtente(utente, 'operatore');
  }

  /**
   * Crea un nuovo utente e l'addetto collegato
   * @param utente nuovo da inserire
   */
  public createUtente(utente: Utente): Promise<ApiResponse<boolean>> {
    if (!utente) throw Error('Utente deve essere diverso da null');
    if (!utente.addetto) throw Error('Utente deve avere un addetto diverso da null');

    const body: any = Object.assign({}, utente);
    /*     body['addetto'] = utente.addetto; */
    const requestUrl: string = `${this.SERVICE_BASE_URL}`;
    return this.post<boolean>(requestUrl, body);
  }

  /**
  * Lista di tutti gli utenti con profilo infermiere
  * @returns lista infermieri oppure un errore @see ApiResponse
  */
  public async getInfermieri(): Promise<ApiResponse<Utente[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}infermiere`;
    return this.get(requestUrl);
  }

  /**
  * Lista di tutti gli utenti con profilo di medico (struttura ed esterni)
  * @returns lista medici di struttura ed esterni oppure un errore @see ApiResponse
  */
  public async getMedici(): Promise<ApiResponse<Utente[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}medico`;
    return this.get(requestUrl);
  }

  /**
  * Lista di tutti gli utenti con profilo operatore
  * @returns lista operatori oppure un errore @see ApiResponse
  */
  public async getOperatori(): Promise<ApiResponse<Utente[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}operatore`;
    return this.get(requestUrl);
  }

  /**
  * Prende un utente ricercandolo per addetto
  * @param addettoId collegato all'utente
  * @returns utente collegato all'addetto oppure un errore @see ApiResponse
  */
  public async getUtenteByAddetto(addettoId: number): Promise<ApiResponse<Utente>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}addetto/${addettoId} `;
    return this.get(requestUrl);
  }

  /**
 * Prende un utente ricercandolo per id
 * @param utenteId che identifica l'utente da ottenere dal db
 * @returns utente oppure un errore @see ApiResponse
 */
  public async getUtenteById(utenteId: number): Promise<ApiResponse<Utente>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}${utenteId}`;
    return this.get(requestUrl);
  }

  /**
   * Lista di tutti gli utenti disponibili
   * @returns lista degli utenti oppure un errore @see ApiResponse
   */
  public async getUtenti(): Promise<ApiResponse<Utente[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL} `;
    return this.get<Utente[]>(requestUrl);
  }

  /**
 * Lista degli utenti disponibili filtrati per profilo (es. medico, infermiere, ecc)
 * @param profilo dell'utente
 * @returns lista degli utenti filtrati per profilo oppure un errore @see ApiResponse
 */
  public async getUtentiByProfilo(profilo: UtenteProfilo): Promise<ApiResponse<Utente[]>> {
    if (!profilo) throw Error('Profilo dell\'utente null.' + this.contactITMsg);

    const id: number = UtenteProfilo.getId(profilo);
    const requestUrl: string = `${this.SERVICE_BASE_URL}profilo/${id}`;
    return this.get<Utente[]>(requestUrl);
  }

  /**
  * Aggiorna le informazioni modificabili di un utente (contatti)
  * @param utente da aggiornare
  */
  public updateUtente(utente: Utente): Promise<ApiResponse<boolean>> {
    if (!utente) throw Error('Utente deve essere diverso da null');

    if (!utente.idrow || utente.idrow <= 0)
      throw Error('Idrow utente non valida. Contattare il supporto IT')

    if (utente.addetto && utente.addettoId !== utente.addetto?.id) {
      throw Error('Utente e addetto non corrispondono. Contattare il supporto IT')
    }

    const body: any = Object.assign({}, utente);
    body['addetto'] = utente.addetto;
    const requestUrl: string = `${this.SERVICE_BASE_URL}${utente.idrow} `;
    return this.put<boolean>(requestUrl, body);
  }

  /**
   * Crea un nuovo utente e l'addetto collegato
   * @param utente nuovo da inserire
   */
  private doCreateUtente(utente: Utente, endpointName:string): Promise<ApiResponse<boolean>> {
      if (!utente) throw Error('Utente deve essere diverso da null');
      if (!utente.addetto) throw Error('Utente deve avere un addetto diverso da null');
  
      const body: any = Object.assign({}, utente);
      const requestUrl: string = `${this.SERVICE_BASE_URL}`+ endpointName;
      return this.post<boolean>(requestUrl, body);
    }
}
