import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

/**
 * Pulsante cerca con icona
 */
@Component({
  selector: 'search-button',
  templateUrl: './search-button.component.html',
  styleUrls: ['./search-button.component.css']
})
export class SearchButtonComponent extends IconBaseButtonComponent {

  constructor() {
    super();
    this.value = 'Cerca';
  }

}

