import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

@Component({
  selector: 'forward-button',
  templateUrl: './forward-button.component.html',
  styleUrls: ['./forward-button.component.css']
})
export class ForwardButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
  }
}
