export class UtenteProfilo {
  private static allValues: { [name: string]: UtenteProfilo } = {};

  public static readonly DOCTOR: UtenteProfilo = new UtenteProfilo('MEDICO', 'Medico');
  public static readonly DOCTOR_EXTERNAL: UtenteProfilo = new UtenteProfilo('MEDICO ESTERNO', 'Medico esterno');
  public static readonly SUPERVISOR: UtenteProfilo = new UtenteProfilo('SUPERVISORE', 'Supervisore tecnico');
  public static readonly INFERMIERE: UtenteProfilo = new UtenteProfilo('INFERMIERE', 'Infermiere');
  public static readonly ADMINISTRATOR: UtenteProfilo = new UtenteProfilo('AMMINISTRATORE', 'Amministratore');

  private constructor(public readonly nome: string, public readonly descrizione: string) {
    UtenteProfilo.allValues[nome] = this;
  }

  public static parse(userRole: string): UtenteProfilo {
    const retval: UtenteProfilo | undefined = UtenteProfilo.allValues[userRole];
    if (!retval) throw new Error('UserType non valido: ' + userRole);

    return retval;
  }

  /**
   * Restituisce l'id di un profilo
   * @param profilo di cui si vuole l'id
   * @returns id del profilo richiesto
   * @throws Error se il profilo non viene riconosciuto
   */
  public static getId(profilo: UtenteProfilo): number {
    let id: number = -1;
    switch (profilo.nome) {
      case UtenteProfilo.ADMINISTRATOR.nome:
        id = 1; break;
      case UtenteProfilo.DOCTOR.nome:
        id = 2; break;
      case UtenteProfilo.SUPERVISOR.nome:
        id = 3; break;
      case UtenteProfilo.INFERMIERE.nome:
        id = 4; break;
      case UtenteProfilo.DOCTOR_EXTERNAL.nome:
        id = 41; break;
      default:
        throw Error('Profilo utente non riconosciuto');
    }
    return id;
  }
}
