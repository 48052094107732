<!-- <div class="filter-group">
  <span class="p-inputgroup-addon">
    <i class="fas fa-search icon-btn"></i>A
  </span>
  <p-calendar [(ngModel)]="mainData" [showIcon]="true" dateFormat="dd/mm/yy" [minDate]="minData"
    [maxDate]="maxData" (onSelect)="onDataChange()" (onInput)="onDataChange()"
    [style]="{'width': '100%', 'flex':'100%'}" [ngStyle]="{'flex': 'auto', 'flex-basis': '100%'}">
  </p-calendar>
</div> -->
<div class="filter-group">
  <span class="p-inputgroup-addon">
    <i class="fas fa-search icon-btn"></i>A
  </span>
  <p-calendar [(ngModel)]="mainData" [showIcon]="true" [dateFormat]="formatoData" [minDate]="minData"
    [maxDate]="maxData" (onSelect)="onDataChange()" (onInput)="onDataChange()">
  </p-calendar>
</div>
