import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';
import { Logger } from 'src/app/shared/utils/logger';
import { AuthService } from '../../core/auth/auth.service';

/**
 * Direttiva per verificare che l'utente abbia almeno uno dei profili
 */
@Directive({
  selector: '[hasAnyProfile]'
})
export class HasAnyProfileDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) { }

    @Input('hasAnyProfile') set haAlmenoProfilo(profiliUtente: UtenteProfilo[]) {
    const condition = this.authService.hasAnyRole(profiliUtente);
   Logger.log('HAS ANY PROFILE?', condition);
    if (condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
