<nav class="navbar navbar-expand navbar-container" *ngIf="isLoggedIn">

  <a class="navbar-brand" routerLink="/home">
    <img [src]="logoPath" alt="Proxmed" width="300px" />
  </a>

  <!-- DOTTORE -->
  <div class="navbar-nav" *hasAnyProfile="[profili.DOCTOR]">

    <!-- pazienti in carico -->
    <a class="nav-link" routerLink="medico/pazienti" (click)="aggiornaConsultiNonVisti()">
      <i class="fas fa-user-circle"></i> Pazienti</a>

      <!-- richieste consulto per il medico -->
    <a class="nav-link" routerLink="medico/richieste-consulto" (click)="aggiornaConsultiNonVisti()">
      <div style="margin-right: 1rem;">
        <i class="fas fa-tasks"></i> Richieste consulto
        <!-- icona notifica nuovi consulti -->
        <i *ngIf="consultiNonVistiCount>0" class="fas fa-exclamation-circle" style="color:#18a2ab"
          pTooltip="Uno o più consulti non hanno ricevuto una risposta"></i>
        <i *ngIf="consultiNonVistiCount < 0" class="fas fa-exclamation-triangle" style="color:red"
          pTooltip="Impossibile sapere quanti consulti non hanno ricevuto una risposta"></i>
      </div>
    </a>
  </div>

  <!-- INFERMIERE -->
  <div class="navbar-nav" *hasAnyProfile="[profili.INFERMIERE]">
    <a class="nav-link" routerLink="infermiere/pazienti"> Pazienti</a>
  </div>

  <!-- AMMINISTRAZIONE -->
  <div class="navbar-nav" *hasAnyProfile="[profili.ADMINISTRATOR]">
    <a class="nav-link" routerLink="amministratore/pazienti"> Pazienti</a>
    <a class="nav-link" routerLink="amministratore/operatori"> Operatori</a>
    <a class="nav-link" routerLink="amministratore/infermieri"> Infermieri</a>
    <a class="nav-link" routerLink="amministratore/medici"> Medici</a>
  </div>

  <!-- SUPERVISORE -->
  <div class="navbar-nav" *hasAnyProfile="[profili.SUPERVISOR]">
    <a class="nav-link" routerLink="supervisore/dispositivi"> Dispositivi</a>
  </div>

<!--   <div style="margin-left: auto;">
    <p-menubar [model]="items"></p-menubar>
  </div> -->

    <div class="navbar-nav" style="margin-left: auto;">
    <p-menubar [model]="items"></p-menubar>
  </div>

</nav>