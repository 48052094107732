import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { UserAuthorization } from 'src/app/shared/models/user-authorization';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('-----------RouteGuard.canActivate---------');
    if (!this.authService.isLoggedIn()) {
      // Notice in the query parameters, in the navigate method,
      // I’m adding the current router state URL
      // in order to redirect the user back from the page where the request originated
      //alert("Completa l'autenticazione");
      this.router.navigate(['login']/*, { queryParams: { returnUrl: state.url } }*/);
      console.log('RouteGuard.canActivate ' + state.url + ': ' + false);
      return false;
    }

    let canActivate = true;

    const userAuthorizations = route.data.authorizations as UserAuthorization[];
    console.log('Servono le seguenti autorizzazioni');
    console.log(userAuthorizations);
    /*  if (userAuthorizations) canActivate = this.authService.hasAnyAuthorizations(userAuthorizations); */
    canActivate = true;

    if (!canActivate) {
      alert('Non hai i permessi per raggiungere questa pagina');
    }

    console.log('Url: ' + state.url + ' canActivate?: ' + canActivate);

    console.log('-------------------------------------------');
    return canActivate;
  }




  public canLoad(route: Route): boolean {
    console.log('-----------RouteGuard.canLoad---------');
    if (!this.authService.isLoggedIn()) {
      console.log('canLoad ' + route.path + ': ' + false);
      return false;
    }
    let canLoad = true;
    const userAuthorizations = route.data?.authorizations as UserAuthorization[];
    /* if (userAuthorizations) canLoad = this.authService.hasAnyAuthorizations(userAuthorizations); */
    canLoad = true;
    console.log('Url: ' + route.path + ' attivabile?: ' + canLoad);
    console.log('-------------------------------------------');
    return canLoad;
  }
}
