import { UserAuthorization } from "src/app/shared/models/user-authorization";
import { UtenteProfilo } from "src/app/shared/models/utente/utente-profilo";

export class AuthenticatedUser {
  public constructor(private user_id: number, private addetto_id: number) {
    this.id = this.user_id;
    this.addettoId = addetto_id;
  }
  public readonly id!: number;
  public readonly addettoId!: number;
  public username!: string;
  public mail!: string;
  public name!: string;
  public surname!: string;
  public language!: string;
  public role!: UtenteProfilo;
  public authorizations!: UserAuthorization[];
}
