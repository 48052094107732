import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';
import { AuthService } from '../auth/auth.service';

/**
 * Reindirizza alla home dell'utente in base al ruolo dello stesso
 * (es. se utente e' medico viene reindirizzato alla home del medico)
 */
@Injectable({
  providedIn: 'root'
})
export class HomeRoleRedirectGuardServiceGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userRole: UtenteProfilo = this.authService.getUserRole();
    if (!userRole) {
      alert('Contattare reparto IT, ruolo utente non riconosciuto');
      this.router.navigate(['/login']);
      throw Error('Ruolo utente non riconosciuto');
    }
    console.info('utente: ' + userRole.descrizione);
    this.router.navigate([`/${userRole.nome}`.toLowerCase()]);
    return true;
  }

}
