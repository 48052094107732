import { Injectable } from '@angular/core';
import { Addetto } from 'src/app/shared/models/addetto';
import { ApiResponse } from 'src/app/shared/models/api-response';
import { Paziente } from 'src/app/shared/models/paziente';
import { Specializzazione } from 'src/app/shared/models/specializzazione';
import { Utente } from 'src/app/shared/models/utente/utente';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';
import { RestBaseService } from './rest-base.service';

@Injectable({
  providedIn: 'root'
})
export class AddettoService extends RestBaseService {
  constructor() {
    super('addetto');
  }

  /**
   * Lista di tutti gli addetti disponibili
   * @returns lista degli addetti oppure un errore @see ApiResponse
   */
  public async getAddetti(): Promise<ApiResponse<Addetto[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}`;
    return this.get<Addetto[]>(requestUrl);
  }

  /**
   * Lista degli addetti disponibili filtrati per profilo (es. medico, infermiere, ecc)
   * @param profilo dell'addetto da ricercare
   * @returns lista degli addetti filtrati per profilo oppure un errore @see ApiResponse
   */
  public async getAddettiByProfilo(profilo: UtenteProfilo): Promise<ApiResponse<Addetto[]>> {
    if (!profilo) throw Error('Profilo dell\'addetto null.' + this.contactITMsg);

    const id: number = UtenteProfilo.getId(profilo);
    const requestUrl: string = `${this.SERVICE_BASE_URL}profilo/${id}`;
    return this.get<Addetto[]>(requestUrl);
  }

  /**
   * Filtra gli addetti in base ad una determinata specializzazione
   * @param specializzazione posseduta dagli addetti
   * @returns addetti che possiedono la specializzazione richiesta oppure @see ApiResponse
   */
  public getAddettiBySpecializzazione(specializzazione: Specializzazione): Promise<ApiResponse<Addetto[]>> {
    if (!specializzazione.id) throw Error('Specializzazione richiesta null.' + this.contactITMsg);

    const requestUrl: string = `${this.SERVICE_BASE_URL}specializzazione/${specializzazione.id}`;
    return this.get(requestUrl);
  }

  /**
   * Prende un addetto ricercandolo per id
   * @param addettoId che identifica l'addetto da ottenere dal db
   * @returns addetto oppure @see ApiResponse
   */
  public async getAddettoById(addettoId: number): Promise<ApiResponse<Addetto>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}${addettoId}`;
    return this.get(requestUrl);
  }

  /**
 * Ricerca il medico di famiglia (MMG) di un paziente
 * @param paziente paziente di cui ricercare il medico MMG
 * @returns MMG se presente, undefined oppure @see ApiResponse
 */
  public async getMedicoMMGByPaziente(paziente: Paziente): Promise<ApiResponse<Addetto | undefined>> {
    if (!paziente?.id) throw Error('Paziente non specificato.' + this.contactITMsg);
    const requestUrl: string = `${this.SERVICE_BASE_URL}paziente/${paziente.id}/mmg`;
    return this.get(requestUrl);
  }

  /**
   * Prende tutte le specialita mediche inserite nel db
   * @returns lista specialita mediche oppure @see ApiResponse
   */
  public getSpecializzazioniAddetti(): Promise<ApiResponse<Specializzazione[]>> {
    const requestUrl: string = `${this.SERVICE_BASE_URL}specializzazione`;
    const response = this.get<Specializzazione[]>(requestUrl, false)
      .then(response => {
        const data = response?.data;
        if (data && Array.isArray(data)) {
          const dataInstances: Specializzazione[] = [];
          data.forEach(spec => dataInstances.push(new Specializzazione(spec)));
          response.data = dataInstances;
        }
        return response;
      });
    return response;
  }

  /**
* Ottiene le specializzazioni per uno specifico addetto
* @param addetto addetto di cui si vogliono conoscere le specializzazioni mediche
* @returns lista specialita mediche oppure @see ApiResponse
*/
  public getSpecializzazioniByAddetto(addetto: Addetto): Promise<ApiResponse<Specializzazione[]>> {
    if (!addetto?.id) throw Error('Addetto e\' null.' + this.contactITMsg);

    const requestUrl: string = `${this.SERVICE_BASE_URL}${addetto.id}/specializzazione`;
    const response = this.get<Specializzazione[]>(requestUrl)
      .then(response => {
        const data = response?.data;
        if (data && Array.isArray(data)) {
          const dataInstances: Specializzazione[] = [];
          data.forEach(spec => dataInstances.push(new Specializzazione(spec)));
          response.data = dataInstances;
        }
        return response;
      });
    return response;
  }

  /**
   * Aggiorna le informazioni modificabili di un addetto (contatti)
   * @param utente a cui l'addetto fa riferimento
   * @param addetto a cui appartengono i dati da aggiornare
   */
  public updateAddettoContatti(utente: Utente, addetto: Addetto): Promise<ApiResponse<boolean>> {
    if (!utente || !addetto) throw Error('utente e addetto devono essere diversi da null');
    if (utente.addettoId !== addetto.id) {
      throw Error('Utente e addetto non corrispondono. Contattare il supporto IT')
    }

    const body: any = Object.assign({}, addetto);
    body['idAddetto'] = utente.id;
    const requestUrl: string = `${this.SERVICE_BASE_URL}recapiti`;
    return this.put<boolean>(requestUrl, body);
  }

  /**
   * Aggiorna le specializzazioni di un addetto
   * @param addetto addetto per cui aggiornare le specializzazioni
   * @param specializzazioni le specializzazioni dell'addetto specificato
  * @returns true se l'aggiornamento ha avuto successo, false in caso di errore @see ApiResponse
   */
  public async updateSpecializzazioniAddetto(addetto: Addetto, specializzazioni: Specializzazione[]): Promise<ApiResponse<boolean>> {
    if (!addetto?.id) throw Error('Addetto null. Impossibile aggiornare le specializzazioni.\n' + this.contactITMsg);
    if (!specializzazioni) throw Error('Specializzazioni null. Impossibile aggiornare le specializzazioni.\n' + this.contactITMsg);

    let body: any = { 'addettoId': addetto.id, 'specializzazioni': specializzazioni };
    const requestUrl: string = `${this.SERVICE_BASE_URL}${addetto.id}/specializzazione`;
    return this.post<boolean>(requestUrl, body);
  }
}
