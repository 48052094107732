import { TemplateRef, ContentChild } from '@angular/core';
import { Component } from '@angular/core';

/**
 * Componente per il posizionamento dei filtri e pulsanti contestuali relativi ad una tabella
 * Sono presenti le seguenti sezioni:
 * -  a) area filtri in alto a sinistra
 * -  b) area pulsanti in linea con i filtri in alto a destra (opzionale)
 * -  c) tre aree pulsanti sotto i filtri (riga sottostante), rispettivamente a (cs)sinistra, (cc)centro e (cd)destra
 *    (ciascuna area opzionale)
 * 
 *  Crea gli stili necessari per generare l'header di una tabella
 *  Width e height sono ereditati dall'elemento, quindi ad esempio
 *      <table-header style="width: 100%; height: auto">...</table-header>
 * 
 *  Se ai bottoni viene impostata la classe "header-table-btn-default" gli verranno impostati gli stili predefiniti
 * 
 *  - Esempio
 *    <table-header style="width: 100%;">
 *       (a)<ng-template #filters>
 *         <input id="filtro"></input>
 *       </ng-template>
 *
 *       (b)<ng-template #filtersButtons>
 *         <button id="bottoneVicinoAiFiltri" class="default"> bottone vicino ai filtri </button>
 *       </ng-template>
 *        
 *       (cs)<ng-template #leftButtons>
 *         <button id="bottoneAllineatoASinistra" class="default">sinistra</button>
 *       </ng-template>
 *
 *       (cc)<ng-template #centerButtons>
 *         <button id="bottoneAllineatoAlCentro" class="default">centro</button>
 *       </ng-template>
 *
 *       (cd)<ng-template #rightButtons>
 *         <button id="bottoneAllineatoADestra" class="default">sinistra</button>
 *       </ng-template>
 *
 *     </table-header>
 * 
 */

@Component({
  selector: 'table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.css']
})
export class TableHeaderComponent {

  @ContentChild('filters', { static: false }) filters!: TemplateRef<any>
  @ContentChild('filtersButtons', { static: false }) filtersButtons!: TemplateRef<any>
  @ContentChild('leftButtons', { static: false }) leftButtons!: TemplateRef<any>
  @ContentChild('centerButtons', { static: false }) centerButtons!: TemplateRef<any>
  @ContentChild('rightButtons', { static: false }) rightButtons!: TemplateRef<any>
}
