/**
 * Risposta base del backend contenente i dati richiesti oppure, in caso di errore, l'eventuale messaggio informativo
 */
export class ApiResponse<T> {
  private error!: boolean;
  private returnCode!: number;
  private returnCodeMessage?: string;
  private returnInfoMessage?: string;

  public data?: T;

  private constructor(init?: Partial<ApiResponse<T>>) {
    Object.assign(this, init);
  }

  public static create<T>(init?: Partial<ApiResponse<T>>): ApiResponse<T> {
    return new ApiResponse<T>(init);
  }

  public static createError<T>(init?: Partial<ApiResponse<T>>): ApiResponse<T> {
    const newObj = new ApiResponse<T>(init);
    newObj.error = true;
    return newObj;
  }

  public static createErrorWithData<T>(data?: T, message?: string): ApiResponse<T> {
    if (Array.isArray(data)) { }
    let newObj = ApiResponse.create<T>({ data: data });
    newObj.returnInfoMessage = message;
    newObj.error = true;
    return newObj;
  }

  public static createErrorWithMessage<T>(message: string): ApiResponse<T> {
    const newObj = new ApiResponse<T>();
    newObj.returnInfoMessage = message;
    newObj.error = true;
    return newObj;
  }

  public static createOk<T>(init?: Partial<ApiResponse<T>>): ApiResponse<T> {
    const newObj = new ApiResponse(init);
    newObj.error = false;
    return newObj;
  }

  public static createOkWithData<T>(data: T, message?: string): ApiResponse<T> {
    const newObj = ApiResponse.create<T>({ data: data });
    newObj.returnInfoMessage = message;
    newObj.error = false;
    return newObj;
  }

  /**
   * Prende il primo dato disponibile
   * @returns primo dato disponibile oppure null se non ci sono dati
   */
  public getDataSingleValue(): T | undefined {
    if (!this.data) return;

    if (Array.isArray(this.data) && this.data.length >= 0) return this.data[0];
    return this.data;
  }

  public getErrorMessage(): string | undefined {
    return this.returnInfoMessage;
  }

  public isError(): boolean {
    return this.error;
  }

  public getStatusCode(): number {
    return this.returnCode;
  }
}
