import { Component } from '@angular/core';
import { TableActionButtonComponent } from '../table-action-button/table-action-button.component';

@Component({
  selector: 'add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.css']
})
export class AddButtonComponent extends TableActionButtonComponent {
  constructor() {super();}
}
