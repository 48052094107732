import { EntityBase } from "./entity-base";
import { Specializzazione } from "./specializzazione";
import { UtenteProfilo } from "./utente/utente-profilo";

export class Addetto extends EntityBase<Addetto>{
  public codiceFiscale!: string;
  public cognome!: string;
  public email!: string;
  public lingua?: string;
  public nascitaData!: Date;
  public nome!: string;
  public partitaIva!: string;
  public sesso!: string;
  public specializzazioni?: Specializzazione[];
  public telFisso!: string;
  public telMobile!: string;

  /**
   * Trasforma sul posto gli elementi dell'array in istanze di Addetto (se non lo sono gia)
   * @param addetti array di elementi che rispettano interfaccia Addetto (non per forza istanze)
   * @returns numero di elementi che hanno avuto bisogno di essere istanziati
   */
  public static checkIstanza(addetti: Addetto[]): number {
    if (Array.isNullOrEmpty(addetti)) return 0;

    let counter: number = 0;
    for (let i = 0; i < addetti.length; i++) {
      const addetto = addetti[i];
      if (!(addetto instanceof Addetto)) {
        addetti[i] = new Addetto(addetto);
        counter++;
      }
    }
    return counter;
  }

  public static createEmpty(): Addetto {
    const addetto = new Addetto();
    addetto.specializzazioni = new Array(0);
    return addetto;
  }

  public equals(other: Addetto): boolean {
    if (!other) return false;
    return this.id === other.id && this.codiceFiscale === other.codiceFiscale;
  }
}
