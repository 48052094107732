export class UserAuthorization {
    private static readonly DELIMITER_CHAR: string = ':';
    private _domain: string;
    private _action: string;

    private constructor(private authorizationValue: string) {
        if (!authorizationValue) throw Error('Autorizzazione non valida');

        const splits: string[] = authorizationValue.split(UserAuthorization.DELIMITER_CHAR);
        if (!splits || splits.length !== 2) throw Error('Autorizzazione non valida');

        this._domain = splits[0];
        this._action = splits[1];
    }

    public static parse(authorization: string): UserAuthorization {
        return new UserAuthorization(authorization);
    }

    public equals(other: UserAuthorization): boolean {
        if (!other) return false;
        let matches = this.authorizationValue.match(other.authorizationValue);
        return matches?.length === 1;
    }

    public static ADDETTI_CREATE: UserAuthorization = new UserAuthorization('ADDETTI:CREATE');
    public static ADDETTI_EDIT: UserAuthorization = new UserAuthorization('ADDETTI:EDIT');
    public static ADDETTI_READ: UserAuthorization = new UserAuthorization('ADDETTI:READ');
    public static ADDETTI_DELETE: UserAuthorization = new UserAuthorization('ADDETTI:DELETE');
}
