import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { UtenteProfilo } from 'src/app/shared/models/utente/utente-profilo';
import { DialogService } from 'primeng/dynamicdialog';
import { UtenteCambioPasswordDialog } from '../../dialogs/utente-cambio-password/utente-cambio-password.dialog';
import { UtenteInfoDialog } from '../../dialogs/utente-info/utente-info/utente-info.dialog';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { StaticResourceService } from '../../services/static-resource.service';
import { Notifica } from '../../models/notifica';
import { BaseController } from 'src/app/shared/controllers/base-controller';
import { ConsultoService } from '../../services/rest/consulto.service';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  providers: [DialogService]
})
export class NavbarComponent extends BaseController implements OnInit, OnDestroy {
  private readonly loggedChangeSubscription!: Subscription;

  private username?: string = '';
  private utenteId: number = 0;

  public readonly logoPath: string = StaticResourceService.getPathLogoProxmedMedportal();

  public consultiNonVistiCount: number = 0;
  public initializationTerminated: boolean = false;
  public isLoggedIn: boolean = false;
  public items: MenuItem[] = [];
  public readonly profili = UtenteProfilo;

  constructor(private dialogService: DialogService, private authService: AuthService, private consultoService: ConsultoService) {
    super();
    this.loggedChangeSubscription = this.authService.loggedChanged
      .subscribe((event: boolean) => this.onLoggedStateChange(event));
  }

  public async aggiornaConsultiNonVisti(): Promise<void> {
    await this.loadNumeroConsultiNonVistati(this.utenteId);
  }

  public changeLoggedUserInfo(): void {
    const ref = this.dialogService.open(UtenteInfoDialog, {
      data: {
        user: this.authService.getUserData()
      },
      header: 'Info utente'
    });
  }

  public changePassword(): void {
    const ref = this.dialogService.open(UtenteCambioPasswordDialog, {
      data: {
        user: this.authService.getUserData()
      },
      header: 'Modifica password',
      width: '50%'
    });
  }

  public logout(): void {
    this.authService.logout();
    this.isLoggedIn = false;
    /*     this.router.navigate(['/login']); */
  }

  public ngOnDestroy(): void {
    this.loggedChangeSubscription?.unsubscribe();
  }

  public async ngOnInit(): Promise<void> {
    this.initData();
  }

  private async initData(): Promise<void> {
    this.isLoggedIn = this.authService.isLoggedIn();
    if (!this.isLoggedIn) return;

    const userData = this.authService.getUserData();
    this.username = userData.username;
    this.utenteId = userData.id;

    if(this.authService.hasAnyRole([UtenteProfilo.DOCTOR, UtenteProfilo.DOCTOR_EXTERNAL]))
    await this.loadNumeroConsultiNonVistati(this.utenteId);

    this.initUserOptionsItems();

    this.initializationTerminated = true;
  }

  private initUserOptionsItems(): void {
    const menuEntries:MenuItem[] = [];

    if(!this.authService.hasRole(UtenteProfilo.ADMINISTRATOR))
    menuEntries.push(
      {
      label: 'Cambia password', icon: 'fas fa-key', styleClass: 'font-size-large',
      command: () => { this.changePassword(); }
    });
    menuEntries.push(
      {
        label: 'Info utente', icon: 'fas fa-info', styleClass: 'font-size-large',
        command: () => { this.changeLoggedUserInfo(); }
      },
      {
        label: 'Logout', icon: 'fas fa-sign-out-alt', styleClass: 'font-size-large',
        command: () => { this.logout(); }
      }
    );
    this.items = [
      {
        label: this.username,
        styleClass: "espanding-menu",
        items: menuEntries
      },
    ];
  }

  /**
 * Carica il numero di consulti non risposti richiesti all'utente loggato (specialista)
 */
  private async loadNumeroConsultiNonVistati(addettoId: number): Promise<void> {
    this.consultiNonVistiCount = 0;
    try {
      const response = await this.consultoService.getConsultiNuoviByDestinatario(addettoId);
      const msg: string = 'Impossibile caricare il numero di consulti non visualizzati per il paziente';
      const notifica: Notifica = this.checkRispostaErroreDatoSingolo(response, msg);
      this.consultiNonVistiCount = notifica.notificheNonLette;
    }
    catch (e) {
      this.consultiNonVistiCount = -1;
    }
  }

  private onLoggedStateChange(event: boolean): void {
    this.isLoggedIn = event;
    if (event) {
      this.username = this.authService.getUserData().username;
      this.initData();
    }
  }
}
