import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Pulsante base con icona opzionale
 */
@Component({
  selector: 'icon-base-button',
  template: '',
  styleUrls: ['./icon-base-button.component.css']
})
export class IconBaseButtonComponent {
  @Input() public icon: string = '';
  @Input() public color?: string;
  @Input() public value: string = '';
  @Input() public disabled: boolean = false;
  @Input() public tooltip?:string;

  @Output() click: EventEmitter<any> = new EventEmitter();
}
