import { Addetto } from "../addetto";
import { EntityBase } from "../entity-base";
import { UtenteProfilo } from "./utente-profilo";

export class Utente extends EntityBase<Utente>{
  public readonly addettoId!: number;

  public addetto?: Addetto;
  public bloccato!: boolean;
  public cognome!: string;
  public nome!: string;
  public profilo!: UtenteProfilo;
  public scadenzaData!: Date;
  public username!: string;

  /**
  * Trasforma sul posto gli elementi dell'array in istanze di Utente (se non lo sono gia)
  * @param utenti array di elementi che rispettano interfaccia Utente (non per forza istanze)
  * @returns numero di elementi che hanno avuto bisogno di essere istanziati
  */
  public static checkIstanza(utenti: Utente[]): number {
    if (Array.isNullOrEmpty(utenti)) return 0;

    let counter: number = 0;
    for (let i = 0; i < utenti.length; i++) {
      const utente = utenti[i];
      if (!(utente instanceof Utente)) {
        utenti[i] = new Utente(utente);
        counter++;
      }
    }
    return counter;
  }

  public static createEmpty(): Utente {
    const utente = new Utente();
    utente.addetto = Addetto.createEmpty();
    return utente;
  }

  /**
  * Estre gli addetti legati all'utente
  * @returns lista di addetti
  * @throws error se almeno un utente non ha l'oggetto addetto
  */
  public static getAddettiFromUtenti(utenti: Utente[]): Addetto[] {
    if (Array.isNullOrEmpty(utenti)) return [];

    const addetti: Addetto[] = new Array<Addetto>(utenti.length);
    utenti.forEach((cur: Utente) => {
      const addetto: Addetto | undefined = cur.addetto;
      if (!addetto) {
        const msg: string = 'Addetto non presente';
        alert(msg);
        throw Error(msg);
      }
      addetti.push(addetto);
    });
    return addetti;
  }

  public equals(other: Utente): boolean {
    if (!other) return false;
    return this.id === other.id && this.username === other.username;
  }
}