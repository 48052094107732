<body *ngIf="utente">
  <form [formGroup]="utenteForm">
    <div style="display: flex; flex-direction: column; row-gap: 1.5rem; margin-top: 20px;">
      <div style="display: flex; flex-direction: row; column-gap: 2rem;">
        <span class="p-float-label">
          <input pInputText type="text" #username formControlName="username">
          <label for="username">Username</label>
        </span>
        <!-- INPUT PER NASCONDERE AUTOCOMPLETAMENTO PASSWORD CORRENTE SE SALVATA NEL BROWSER. DATI NON USATI -->
        <p-password autocomplete="false" [inputStyle]="{'display':'none'}"></p-password>
      </div>

      <div style="display: flex; flex-direction: row; column-gap: 2rem;">
        <span class="p-float-label">
          <p-password [toggleMask]="true" [feedback]="false" #passwordCorrente formControlName="passwordCorrente">
          </p-password>
          <label for="passwordCorrente">Password attuale</label>
        </span>
        <span class="p-float-label">
          <p-password [toggleMask]="true" [feedback]="true" #nuovaPassword formControlName="nuovaPassword">
          </p-password>
          <label for="nuovaPassword">Nuova password</label>
        </span>
        <span class="p-float-label">
          <p-password [toggleMask]="true" [feedback]="true" #nuovaPasswordConferma
            formControlName="nuovaPasswordConferma"></p-password>
          <label for="nuovaPasswordConferma">Conferma nuova password</label>
        </span>
      </div>
    </div>
  </form>
  <div *ngIf="utenteForm.touched && !utenteForm.valid && utenteForm.errors?.passwordEquality"
    style="color: red; font-style: italic;">
    <label>Le password nuova e la conferma non coincidono</label>
  </div>

  <!-- PULSANTI SALVA/ANNULLA -->
  <div class="button-container">
    <p-button label="Salva" styleClass="button-action-default-save" (click)="closeAndSaveChanges()"
      [disabled]="!(utenteForm.touched && this.utenteForm.valid)" pTooltip="Salva la nuova password"
      tooltipPosition="top" icon="fa fa-check-circle" iconPos="left">
    </p-button>
    <p-button label="Annulla" (click)="closeAndAbortChanges()" styleClass="button-action-default-cancel"
      pTooltip="Annulla le modifiche alla password e mantieni quella attuale" tooltipPosition="top"
      icon="fa fa-times-circle" iconPos="left"></p-button>
  </div>
</body>