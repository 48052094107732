import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AddettoService } from 'src/app/core/services/rest/addetto.service';
import { Addetto } from 'src/app/shared/models/addetto';
import { Utente } from 'src/app/shared/models/utente/utente';

/**
 * Dialog che permette all'utente loggato di cambiare alcune proprie info, tra cui i contatti
 */
@Component({
  selector: 'utente-info',
  templateUrl: './utente-info.dialog.html',
  styleUrls: ['./utente-info.dialog.css']
})
export class UtenteInfoDialog implements OnInit {
  public initializationEnded: boolean = false;
  public utente!: Utente;
  private addetto!: Addetto;
  private readonly TEL_FISSO = 'telFisso';
  private readonly TEL_MOBILE = 'telMobile';
  private readonly EMAIL = 'email';

  /**
   * Form principale contenente tutti i campi
   */
  public infoUtenteForm!: FormGroup;

  constructor(private ref: DynamicDialogRef, private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private addettoService: AddettoService) {
    this.utente = config.data?.user;
    if (!this.utente) throw Error('Prametro di input utente non valido');
  }

  public async ngOnInit(): Promise<void> {
    const addettoResponse = await this.addettoService.getAddettoById(this.utente.addettoId);
    let mex: string = 'Impossibile ottenere i dati dell\'addetto';
    if (!addettoResponse) { alert(mex); this.closeAndAbortChanges(); }
    let addettoTmp = addettoResponse.getDataSingleValue();
    if (addettoResponse.isError() || !addettoTmp) {
      alert(mex + '\n' + addettoResponse.getErrorMessage());
      this.closeAndAbortChanges();
      return;
    }
    this.addetto = addettoTmp;
    this.initFormDatiUtente();
    this.initializationEnded = true;
  }
  public getTelFisso(): string {
    return this.infoUtenteForm.get(this.TEL_FISSO)?.value;
  }

  public getTelMobile(): string {
    return this.infoUtenteForm.get(this.TEL_MOBILE)?.value;
  }

  public getEmail(): string {
    return this.infoUtenteForm.get(this.EMAIL)?.value;
  }

  public async closeAndSaveChanges(): Promise<void> {
    if (!this.infoUtenteForm) return;
    if (this.infoUtenteForm.invalid) return;
    this.addetto.email = this.getEmail();
    this.addetto.telFisso = this.getTelFisso();
    this.addetto.telMobile = this.getTelMobile();

    const response = await this.addettoService.updateAddettoContatti(this.utente, this.addetto);
    if (response.isError()) {
      alert('Impossibile salvare le modifiche ai dati: ' + response.getErrorMessage());
      return;
    }

    alert('Dati utente aggiornati con successo');
    this.ref.close(true);
  }

  public closeAndAbortChanges(): void {
    this.ref.close(false);
    return;
  }

  public leastTelephoneContactValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.dirty) return null;
      const nuovaPass = String(control.get('nuovaPassword')?.value);
      const nuovaPassConferma = String(control.get('nuovaPasswordConferma')?.value);

      // Valido solo se le due nuove password sono inserite ed uguali fra loro
      if (nuovaPass && nuovaPassConferma && nuovaPassConferma === nuovaPass) return null;

      return { leastTelephoneContact: { value: false } };
    };
  }

  private initFormDatiUtente(): void {
    this.infoUtenteForm = this.formBuilder.group({
      username: [{ value: this.utente.username, disabled: true }],
      codiceFiscale: [{ value: this.addetto.codiceFiscale, disabled: true }],
      nome: [{ value: this.addetto.nome, disabled: true }],
      cognome: [{ value: this.addetto.cognome, disabled: true }],
      [this.TEL_FISSO]: [this.addetto.telFisso, [Validators.required, Validators.minLength(5)]],
      [this.TEL_MOBILE]: [this.addetto.telMobile],
      [this.EMAIL]: [this.addetto.email, [Validators.required, Validators.minLength(5)]]
    }, { updateOn: 'change', validators: this.leastTelephoneContactValidator() });
  }
}
