import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

@Component({
  selector: 'delete-button',
  templateUrl: './delete-button.component.html',
  styleUrls: ['./delete-button.component.css']
})
export class DeleteButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
  }
}