export class Logger {

  public static log(message: string, object: any): void {
    console.log(message);
    console.log(JSON.parse(JSON.stringify(object)));
  }

  public static printDates(dates: Date[]): void {
    let strDates: string[] = [];
    for (let date of dates) {
      strDates.push(date?.toLocaleDateString());
    }
    console.log(JSON.parse(JSON.stringify(strDates)));
  }
}
