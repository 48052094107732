import { ApiResponse } from "../models/api-response";
import { UserAuthorization } from "../models/user-authorization";

/**
 * Controller base con alcune funzionalita comuni
 */
export abstract class BaseController {
  public readonly userAuth = UserAuthorization;
  /**
 * Estrae i dati dalla risposta se questa non e' un errore. Altrimenti mostra un alert(opzionale) e lancia un'eccezione.
 * ATTENZIONE: importante settare il parametro dataIsArray
 * @param risposta risposta ricevuta dall'api
 * @param msgErrore eventuale messaggio d'errore da mostrare all'utente
 * @param dataIsArray i dati ritornati dall'api vanno considerati come una lista di dati oppure una entita singola?
 * @param mostraAlert mostrare un alert all'utente in caso di errore? (default: true)
 * @returns dati contenuti nella risposta se questa non e' un errore
 * @throws Error se risposta non presente oppure e' un errore oppure non ci sono dati
 */
  private checkRispostaErrore<T>(risposta: ApiResponse<T>, msgErrore: string = 'Dati non caricati', dataIsArray: boolean, mostraAlert: boolean): T {
    const data: T | undefined = dataIsArray ? (risposta?.data ?? [] as unknown as T) : risposta?.getDataSingleValue();

    if (!risposta || risposta.isError() || !data) {
      const msg: string = msgErrore + '\n' + risposta?.getErrorMessage();
      if (mostraAlert) alert(msg);
      throw Error(msg);
    }
    return data;
  }

  /**
   * Estrae i dati (array) dalla risposta se questa non e' un errore. Altrimenti mostra un alert(opzionale) e lancia un'eccezione
   * @param risposta risposta ricevuta dall'api
   * @param msgErrore eventuale messaggio d'errore da mostrare all'utente
   * @param mostraAlert mostrare un alert all'utente in caso di errore? (default: true)
   * @returns dati contenuti nella risposta se questa non e' un errore
   * @throws Error se risposta non presente oppure e' un errore oppure non ci sono dati
   */
  protected checkRispostaErroreDatiMultipli<T>(risposta: ApiResponse<T>, msgErrore: string, mostraAlert: boolean = true): T {
    return this.checkRispostaErrore(risposta, msgErrore, true, mostraAlert);
  }

  /**
   * Estrae il dato (NON array) dalla risposta se questa non e' un errore. Altrimenti mostra un alert(opzionale) e lancia un'eccezione
   * @param risposta risposta ricevuta dall'api
   * @param msgErrore eventuale messaggio d'errore da mostrare all'utente
   * @param mostraAlert mostrare un alert all'utente in caso di errore? (default: true)
   * @returns dato singolo contenuto nella risposta se questa non e' un errore
   * @throws Error se risposta non presente oppure e' un errore oppure non ci sono dati
   */
  protected checkRispostaErroreDatoSingolo<T>(risposta: ApiResponse<T>, msgErrore: string, mostraAlert: boolean = true): T {
    return this.checkRispostaErrore(risposta, msgErrore, false, mostraAlert)
  }
}
