import { Component, Input } from '@angular/core';

@Component({
  selector: 'icon-label',
  templateUrl: './icon-label.component.html',
  styleUrls: ['./icon-label.component.css']
})
/**
 * Etichetta con un'icona vicina (sinistra)
 * INPUT:
 * - label:string = testo dell'etichetta vicino all'icona. Obbligatorio
 * - icon:string = codice dell'icona fontawesome free
 */
export class IconLabelComponent {
  /** codice dell'icona fontawesome free
   * (vedi https://fontawesome.com/v5/search?o=r&m=free), verificare la versione di fontawesome nel package.json o index.html
  verificare la version  **/
 @Input() public icon?: string;
  /* testo dell'etichetta vicino all'icona */
  @Input() public label!: string;
}
