import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AuthService } from "src/app/core/auth/auth.service";
import { UserAuthorization } from "../models/user-authorization";

/**
 * Direttiva per verificare che l'utente abbia almeno una delle autorizzazioni richieste
 */
@Directive({
  selector: '[reqAnyAuths]'
})
export class ReqAnyAuthsDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService) { }

    @Input() set reqAnyAuths(authorizations: UserAuthorization[]) {
      let condition = this.authService.hasAnyAuthorizations(authorizations);
      if (condition) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
}
