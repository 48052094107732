import { Component } from '@angular/core';
import { IconBaseButtonComponent } from '../icon-base-button/icon-base-button.component';

@Component({
  selector: 'cancel-button',
  templateUrl: './cancel-button.component.html',
  styleUrls: ['./cancel-button.component.css']
})
export class CancelButtonComponent extends IconBaseButtonComponent {
  constructor() {
    super();
    this.value = "Annulla"
  }
}
