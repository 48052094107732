import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Logger } from 'src/app/shared/utils/logger';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn() && this.authService.isTokenExpired()) {
      alert('Autenticazione scaduta, rifare il login');
      this.authService.logout();
      return EMPTY;
    }
    else {
      const accessToken = this.authService.getAccessToken();
     /*  if(!accessToken) console.log('TOKEN VUOTO VUOTO VUOTO');
      Logger.log('TOKEN', String(accessToken));
      Logger.log('URL', String(request.url)); */
      const header = this.addTokenToHeader(request.headers, accessToken);
      const authReq = request.clone({ headers: header });
/*       Logger.log('REQUEST', authReq); */
      return next.handle(authReq);
    }
    // return next.handle(request) as any;
  }

  private addTokenToHeader(header: HttpHeaders, accessToken: any): HttpHeaders {
    header = header.set('Authorization', `Bearer ${accessToken}`)
      .set('x-wingest-appinfo', 'asa')
      .set('x-wingest-societa', '1')
      .set('x-wingest-tenant-id', 'proxmed')

    if (!header.has('accept'))
      header = header.set('accept', 'application/json');

    return header;
  }
}